import DragDrawerModal from '../../../../Modal/DragDrawerModal';

import {
  Wrapper,
  Container,
} from './styles';

interface IComissionWarning {
  open: boolean,
  setOpen: Function,
}

const ComissionWarning = ({
  open,
  setOpen,
}: IComissionWarning) => {
  const handleCloseModal = () => {
    setOpen(false);
  }

  const MessageContent = () => (
    <p><strong>Alteração na Lógica de Troca de Comissões</strong><br />
    As regras de troca de comissão devem seguir a lógica de mês +1.<br /><br />
    Exemplo:<br />
    Alteração em 01/01 → Aplicada para reservas com check-in a partir de 01/02.<br />
    <br />
    ⚠️ Considere essa regra ao aplicar o ajuste.
    </p>
  );


  return (
    <Wrapper>
      <DragDrawerModal
        title="Atenção!"
        open={open}
        onClose={() => handleCloseModal()}
        maxWidthForModalOnDesk={'500px'}
        maxHeightForModalOnDesk={'230px'}
        activeDragHandAnimation
      >
        <Container>
          <MessageContent />
        </Container>
      </DragDrawerModal>
    </Wrapper>
  );
};

export default ComissionWarning;
