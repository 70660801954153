import { useEffect } from 'react';

import IconAdd from './icons/IconAdd';
import IconRemove from './icons/IconRemove';
import IconProfile from './icons/IconProfile';
import Logo from './icons/Logo';

import useActivity from './hooks/useActivity';
import useUniverseMappings from '../../components/Sidebar/hooks/useUniverseMappings';
import { useRequestSupportModal } from '../../hooks/useRequestModal';

import { useToast } from '../../context/ToastContext';
import { ActivitiesProps } from './context/FavoriteActivitiesContext';
import { UniversesOptions } from '../../context/MenuLateral';

import ModalAddActivity from './components/Modal/ModalAddActivity';

import {
  Container,
  Header,
  Content,
  Welcome,
  Title,
  Text,
  Activities,
  ActivityContent,
  ActivityLink,
  Activity,
} from './styles';


const Home = () => {
  const toast = useToast();
  const { universeIconsBlueMappings } = useUniverseMappings();
  const { setIsOpenedRequestHostOpsModal } = useRequestSupportModal();

  const {
    favoriteActivities,
    handleRemoveActivity,
    openModalAddActivity,
    setOpenModalAddActivity
  } = useActivity();

  interface IActivityComponent {
    id: number,
    activity: ActivitiesProps,
  };

  interface IRenderActivity {
    activity: ActivitiesProps,
  };

  const RenderActivity = ({ activity }: IRenderActivity) => {
    if (activity.title === 'Formulário Primeira Visita'){
      return (
        <Activity className="customStyle" onClick={() => window.open("https://app.pipefy.com/public/form/I96wBxVW", "_blank")}>
          <p>{activity.title}</p>
        </Activity>
      );
    }

    if (activity.title === 'Criação de Subconta'){
      return (
        <Activity className="customStyle" onClick={() => setIsOpenedRequestHostOpsModal(true)}>
          <p>{activity.title}</p>
        </Activity>
      );
    }

    if (activity.path !== '') {
      return (
        <ActivityLink to={activity.path}>
          {activity.title}
        </ActivityLink>
      )
    }

    return <Activity>{activity.title}</Activity>
  };


  const ActivityComponent = ({ id, activity }: IActivityComponent) => (
    <ActivityContent>
      {activity.universe !== '' ? universeIconsBlueMappings[activity.universe as UniversesOptions] : (
        <IconAdd handleAdd={ () => setOpenModalAddActivity({
              activityId: id,
              open: true,
            })
          }
        />
      )}

      <RenderActivity activity={activity} />

      {activity.title !== 'Adicionar Atividade' && (
        <IconRemove handleRemove={() => {
          handleRemoveActivity(id);
          toast.success('Atividade removida com sucesso!');
        }}
        />
      )}
    </ActivityContent>
  );

  useEffect(() => {
    document.title = 'Sapron | Home';
  }, []);

  return (
    <>
      <Header>
        <IconProfile />
      </Header>

      <Container>
        <Content>
          <Welcome>
            <Logo />
            <Title>Bem-vindo <strong>Seazoner</strong></Title>
          </Welcome>

          <Text>Acesse aqui as suas principais atividades</Text>

          <Activities>
            {favoriteActivities.map((activity, index) => (
              <ActivityComponent
                key={`${activity}-${index + 1}`}
                id={index}
                activity={activity}
              />
            ))}
          </Activities>
        </Content>
      </Container>
      {openModalAddActivity.open && (
        <ModalAddActivity />
      )}
    </>
  );
}

export default Home;
