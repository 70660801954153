import { useMenuLateral } from '../../../../context/MenuLateral';
import { Container } from './styles';

const RetractMenuButtonMobile = () => {
  const { setRetractMenu } = useMenuLateral();

  return (
    <Container onClick={() => setRetractMenu(true)}>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M10.4727 4.47157C10.7331 4.21122 10.7331 3.78911 10.4727 3.52876C10.2124 3.26841 9.79025 3.26841 9.5299 3.52876L5.5299 7.52876C5.26955 7.78911 5.26955 8.21122 5.5299 8.47157L9.5299 12.4716C9.79025 12.7319 10.2124 12.7319 10.4727 12.4716C10.7331 12.2112 10.7331 11.7891 10.4727 11.5288L6.94411 8.00016L10.4727 4.47157Z" fill="white" />
      </svg>
    </Container>
  );
}

export default RetractMenuButtonMobile;
