import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  #title-modal {
    font-family: 'Inter' !important;
    font-size: 22px !important;
    font-weight: 700 !important;
    line-height: 32px !important;
    text-align: left !important;
    color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
  }

  #content-modal {
    @media(min-width: 900px) {
      padding: 0px 32px !important;
    }
  }

  #header-modal {
    padding: 14px 0px 10px 0px !important;
    margin-bottom: 24px !important;
  }

  #footer-modal {
    div {
      min-height: 0px !important;
      padding: 17px 32px !important; 
    }
  }

  .labelClassName {
    font-family: 'Inter' !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 16.94px !important;
    text-align: left !important;
    color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
  }
`;

export const Container = styled.form``;

export const Content = styled.div<{ error?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  max-width: 80%;
  gap: 16px;
  
  ${({ error }) => error && css`
    align-items: center;  
    height: 100px;
  `};

  .field-deal {
    font-family: 'Inter' !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
    line-height: 16.94px !important;
  }

  @media(max-width: 900px) {
    flex-direction: column;
    max-width: 100%;
    height: auto;
  }
`;

export const ContainerSpinner = styled.div`
  width: 100%;
  position: absolute;
  top: -40px;
  right: 4px;
`;

