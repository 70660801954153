import { Outlet, useLocation } from 'react-router-dom';
import { ReportBugModal } from '../../components/ReportBugModal/ReportBugModal';
import HeaderMobile from '../../components/Sidebar/HeaderMobile';
import MenuLateral from '../../components/Sidebar/MenuLateral';
import { useRequestSupportModal } from '../../hooks/useRequestModal';
import { Container } from './styles';
import RequestHostOpsModal from '../../components/RequestHostOpsModal';

const CUSTOM_STYLE_PAGES = ['/reembolso'];

const DefaultLayout = () => {
  const location = useLocation();
  const {
    isOpenedReportErrorModal,
    handleCloseReportBugModal,
    isOpenedRequestHostOpsModal,
    setIsOpenedRequestHostOpsModal,
  } = useRequestSupportModal();

  const isCustomStyle = CUSTOM_STYLE_PAGES.includes(location.pathname);

  return (
    <div>
      <HeaderMobile />
      <MenuLateral />
      <Container isCustomStyle={isCustomStyle} className="container-auto">
        <Outlet />
        {isOpenedReportErrorModal && (
          <ReportBugModal
            isModalOpen={isOpenedReportErrorModal}
            handleCloseModal={handleCloseReportBugModal}
          />
        )}
      
        {isOpenedRequestHostOpsModal && (
          <RequestHostOpsModal handleCloseModal={() => setIsOpenedRequestHostOpsModal(false)} />
        )}

      </Container>
    </div>
  );
};

export default DefaultLayout;
