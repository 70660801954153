import styled, { keyframes } from 'styled-components';

export const FadeAnimation = keyframes`
  from {
    opacity: 0;
    transform: translateY(10rem);
  }
  to {
    opacity: 1;
    transform: translateY(0rem);
  }
`;

export const RequestHostOpsModalBackdrop = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RequestHostOpsModalContent = styled.div`
  background-color: ${({ theme }) => theme.palette.white.main.hex()};
  width: 700px;
  padding: 1rem;
  border-radius: 10px;
  animation-name: ${FadeAnimation};
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  
  @media (max-width: 480px) {
    overflow: hidden;
    width: 100%;
    height: 100vh;
    min-width:0px;
  }
`;

export const RequestHostOpsModalTitle = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    > h2 {
      font-weight: 600;
      font-size: 20px;
      font-family: ${({ theme }) => theme.fonts.familys._1};
      color: #151B26;
    }
  `;

export const RequestHostOpsModalForm = styled.form`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: auto;
  height: 70vh;
  padding: 0 0.5rem;

  .two-itens {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;

  }

  .selector {
    width: 100%;
    justify-self: flex-start;
  }
`;

export const RequestHostOpsModalButtonsContainer = styled.div`
  margin: 1rem 0 2rem 0;
  padding: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;

export const ButtonClose = styled.button`
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  margin-bottom: 12px !important;

  &:hover {
    opacity: 0.6;
    transition: opacity 300ms;
  }

  svg {
    color: #151B26;
  }

  @media(min-width: 900px) {
    margin-bottom: 0 !important;
    margin-top: 4px !important;
  }
`;
