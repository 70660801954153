import { useNavigate } from 'react-router-dom';
import { Container } from './styles';

const Logo = () => {
  const navigate = useNavigate();

  return (
    <Container onClick={() => navigate('/home')}>
      <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M46.4253 14.8903L37.8208 10.1094C37.8375 9.9928 37.8909 9.88458 37.8892 9.76821L37.7812 2.36966C37.7632 1.14328 36.7303 0.164526 35.4733 0.182887L30.9224 0.249362C29.6655 0.267723 28.6615 1.27623 28.6794 2.50261L28.7172 5.08516L25.5947 3.36244C24.327 2.64677 22.6727 2.79628 21.5198 3.65476L21.4605 3.67801L1.99256 15.0738C0.368728 16.051 -0.282455 18.2766 0.76024 19.9222C1.84055 21.63 4.17324 22.0615 5.79249 21.0843L6.95723 20.4047C5.60596 23.0792 4.78272 26.0907 4.83051 29.3625C4.90609 34.5365 7.01743 39.6048 10.8329 43.2245C12.3312 44.6486 14.0692 45.8319 15.9677 46.6951C17.4274 47.3587 18.968 47.9092 20.5641 48.1724C22.6798 48.5176 24.8607 48.2932 26.994 48.2665C29.2969 48.2329 31.6046 48.2037 33.9076 48.1745C35.995 48.1485 38.0825 48.1269 40.1928 48.1006C40.7204 48.0929 41.2368 47.951 41.7013 47.7115C42.2979 47.4028 42.651 47.0708 43.038 46.5504C43.4791 45.9619 43.7808 45.2547 43.7698 44.5027L43.4296 21.2105C45.002 21.7382 46.8942 21.2315 47.8033 19.6962C48.7974 18.0207 48.0815 15.8152 46.4253 14.8858M36.8158 29.2624C36.9192 36.3431 32.09 42.1708 24.6811 42.279C17.2722 42.3873 11.7373 36.3439 11.6339 29.2631C11.5304 22.1824 16.8897 16.2037 24.2986 16.0954C31.7075 15.9872 36.7006 21.376 36.8029 28.3806L36.8158 29.2669L36.8158 29.2624Z" fill="#FC6058" />
      </svg>
    </Container>
  );
}

export default Logo;
