import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useFormik } from 'formik';

import { Container, Form } from './styles';

import usePropertyManager from '../../../../hooks/usePropertyManager';
import DropdownAutocomplete from '../../../DropdownAutocomplete/DropdownAutocomplete';
import FormButton from '../../../FormButton';
import SimpleSelect from '../../../SimpleSelect';
import TextField from '../../../TextField';

import { useToast } from '../../../../context/ToastContext';
import { PostListingProps } from '../../../../services/Listing/types';
import { getOtas } from '../../../../services/Property/request';
import { SelectOption } from '../../../SimpleSelect/SimpleSelect';
import { FormikValues } from './types';

import {
  ErrorMessage,
  useToastErrorMessage,
} from '../../../../utils/Messages';

import {
  PropertyStatus,
  RequestOta,
} from '../../../../services/Property/types';

import {
  formattListDropdown,
  getListingSelected,
  validation,
} from './utils';

import {
  getListings,
  patchListings,
  postListing,
} from '../../../../services/Listing/request';
import { translateStatus } from '../../utils';

type PropertiesProps = {
  id: number;
  code: string;
  status: PropertyStatus
};

interface FormAddListingProps {
  isEditingForm: boolean
}

const FormAddListing = ({ isEditingForm }: FormAddListingProps) => {
  const toast = useToast();
  const navigate = useNavigate();
  const toastErrorRequest = useToastErrorMessage();

  const {
    data: propertyManager,
    loading: propertysLoading,
  } = usePropertyManager();

  const [otas, setOtas] = useState<RequestOta[]>([]);
  const [listings, setListings] = useState<PostListingProps[]>([]);
  const [listingEdit, setListingEdit] = useState<PostListingProps>();
  const [properties, setProperties] = useState<PropertiesProps[]>([]);

  const dropdownList = useMemo(() => formattListDropdown({
    otas,
    listings,
    properties,
  }), [listings, otas, properties]);

  const initialValues: FormikValues = useMemo(() => ({
    idOTA: listingEdit?.id_in_ota || '',
    titleOTA: listingEdit?.title_in_ota || '',
    ota: listingEdit?.ota ? listingEdit?.ota : -1,
    property: listingEdit?.property ? listingEdit?.property : -1,
    feeOTA: listingEdit?.ota_fee ? listingEdit?.ota_fee * 100 : undefined,
  }), [listingEdit]);

  const handleGetListings = async () => {
    try {
      const response = await getListings();
      setListings(response);
    } catch (e: unknown) {
      if (e instanceof Error) {
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        const listing: PostListingProps = {
          ota: values.ota,
          id_in_ota: values.idOTA,
          property: values.property,
          ota_fee: values.feeOTA ? values.feeOTA / 100 : 0,
          title_in_ota: values.titleOTA,
        };

        if (isEditingForm && listingEdit?.id) {
          await patchListings(listingEdit?.id, listing);
          handleGetListings();
        } else {
          await postListing(listing);
        }
        formik.resetForm();
        if (!isEditingForm) {
          toast.success('Listing criado com sucesso!');
        } else {
          toast.success('Listing editado com sucesso!');
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          toast.error(e.message || ErrorMessage.default());
          toastErrorRequest(e);
        }
      }
    },
  });

  useEffect(() => {
    const handleGetOtas = async () => {
      try {
        const response = await getOtas();
        setOtas(response);
      } catch (e: unknown) {
        if (e instanceof Error) {
          toast.error(e.message || ErrorMessage.default());
          toastErrorRequest(e);
        }
      }
    };

    handleGetOtas();
    handleGetListings();
  }, []);

  useEffect(() => {
    const filteredProperties: PropertiesProps[] | undefined = propertyManager?.map((item) => ({
      id: item.id,
      code: item.code,
      status: item.status as PropertyStatus,
    }));
    setProperties(filteredProperties || []);
  }, [propertyManager]);

  useEffect(() => {
    if (formik.values.listing) {
      const result = listings
        .find((item) => item.id?.toString() === formik.values.listing?.toString());
      if (result) {
        formik.setFieldValue('ota', result.ota);
        formik.setFieldValue('idOTA', result.id_in_ota);
        formik.setFieldValue('property', result.property);
        formik.setFieldValue('titleOTA', result.title_in_ota);
      }
      setListingEdit({
        id: result?.id,
        ota: result?.ota || 0,
        ota_fee: result?.ota_fee || 0,
        property: result?.property || 0,
        id_in_ota: result?.id_in_ota || '',
        title_in_ota: result?.title_in_ota || '',
        property_code:
          properties
          && properties?.find((item) => item.id.toString() === result?.property?.toString())?.code,
      });
    }
  }, [formik.values.listing]);

  return (
    <Container>
      <Form onSubmit={formik.handleSubmit}>
        {isEditingForm && (
          <div className="one-column">
            <DropdownAutocomplete
              required
              id="listing"
              formik={formik}
              labelClassName="labelClass"
              label="Selecione o Listing"
              options={dropdownList}
              placeholder={
                formik?.values?.listing
                  ? `${getListingSelected(listings, formik?.values?.listing)}`
                  : 'Digite aqui...'
              }
            />
          </div>
        )}

        <div className="two-columns">
          <SimpleSelect
            id="ota"
            required
            formik={formik}
            placeholder="Selecione"
            label="OTA - Origem da reserva"
            options={(otas || []).map<SelectOption>(({ id, name }) => ({
              value: id,
              valueLabel: name,
            }))}
          />

          <div className="dropdown-property">
            <DropdownAutocomplete
              id="property"
              label="Imóvel"
              formik={formik}
              loading={propertysLoading}
              options={propertyManager?.map((item) => ({
                optionText: `${item.code} - ${translateStatus(item.status)}`,
                optionValue: String(item.id),
              }))}
              placeholder={
                listingEdit?.property_code
                  ? listingEdit?.property_code
                  : 'Digite aqui...'
              }
            />
          </div>
        </div>
        <div className="two-columns">
          <TextField
            id="idOTA"
            formik={formik}
            label="ID na OTA"
            placeholder="Digite aqui..."
            value={listingEdit?.id_in_ota || formik.values.idOTA}
          />
          <TextField
            id="titleOTA"
            formik={formik}
            label="Título na OTA"
            placeholder="Digite aqui..."
            value={listingEdit?.title_in_ota || formik.values.titleOTA}
          />
        </div>

        <div className="two-columns">
          <TextField
            id="feeOTA"
            formik={formik}
            label="Taxa da OTA (em %)"
            placeholder="Digite aqui..."
            value={
              listingEdit?.ota_fee
                ? listingEdit?.ota_fee * 100
                : formik.values.feeOTA
            }
          />
        </div>

        <div className="footer-buttons">
          <div>
            <FormButton
              type="button"
              variant="outlined"
              onClick={() => navigate('/home')}
            >
              Cancelar
            </FormButton>
            <FormButton type="submit">
              Salvar
            </FormButton>
          </div>
        </div>
      </Form>
    </Container>
  );
};

export default FormAddListing;
