/* eslint-disable no-alert */
/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  useState, FC, useContext, useEffect,
} from 'react';
import Calendar, { Detail } from 'react-calendar';

import {
  ArrowBackIos as ArrowBackIosIcon,
  ArrowForwardIos as ArrowForwardIosIcon,
} from '@mui/icons-material';

import moment from 'moment';

import { FastDataFowardModal } from './styles';
import { CalendarContext } from '../../Calendar';
import { useSearch } from '../../../../hooks/SearchHook/useSearch';
import { useLoader } from '../../../../context/LoaderContext';
import { FastDataForwardContext } from '../FastDataForwardButton';
import { UseFormik } from '../../../../utils/Formik/types';
import { useToast } from '../../../../context/ToastContext';
import { useToastErrorMessage, ErrorMessage } from '../../../../utils/Messages';
import { getMonthNumber, NEW_DATE_DEFAULT } from '../../../../utils/Date';

interface FastDataForwardModalProps {
  id: string;
  setDates: Function;
  onClose: () => void;
  setSelectedDay?: Function;
  setSelectedMonth?: Function;
  setSelectedYear?: Function;
  showTodayButton?: boolean;
  defaultView?: Detail | undefined;
  defaultValue?: Date | undefined;
  isOwnerPage?: boolean;
  isFinancialStatementCalendar?: boolean;
  minDate?: Date;
  formik?: UseFormik<any>;
}

const FastDataForwardModal: FC<FastDataForwardModalProps> = ({
  id,
  onClose,
  setDates,
  setSelectedDay,
  showTodayButton,
  defaultView,
  setSelectedMonth,
  setSelectedYear,
  defaultValue,
  isOwnerPage = false,
  isFinancialStatementCalendar = false,
  minDate,
}) => {
  const { dateSelected, setDateSelected } = useContext(FastDataForwardContext);
  const [calendarValue, setCalendarValue] = useState(dateSelected);
  const calendarStartValue = new Date();
  const [isToday, setIsToday] = useState(true);

  const { getReservation } = useContext(CalendarContext);
  const { filteredResults } = useSearch();
  const { setLoad } = useLoader();

  const toastErrorRequest = useToastErrorMessage();
  const toast = useToast();

  const handleOutClick = (event: any) => {
    if (event.target.id === id) onClose();
  };

  const handleOnClickMonth = (month: Date) => {
    if (setSelectedMonth) {
      setSelectedMonth(month);
    }
  };

  const handleOnClickYear = (year: Date) => {
    if (setSelectedYear) {
      setSelectedYear(year);
    }
  };

  const handleOnClickDay = async (value: Date) => {
    if (setSelectedDay) {
      setSelectedDay(value);
    }

    try {
      setLoad(true);
      setCalendarValue(value);
      setDateSelected(value);
      const prevDate = Array.from({ length: 15 }, (_, days: number) => moment(moment(value).add(-(days + 1), 'days').toDate())).reverse();
      const nextDate = Array.from({ length: 15 }, (_, days: number) => moment(moment(value).add(days + 1, 'days').toDate()));
      setDates([...prevDate, moment(value), ...nextDate]);
      await getReservation(filteredResults, [...prevDate, moment(value), ...nextDate]);
      const element: HTMLElement | null = document.getElementById(moment(value).format('YYYY-MM-DD'));
      element?.scrollIntoView({ inline: 'center' });
      setLoad(false);
    } catch (e: unknown) {
      if (e instanceof Error) {
        setLoad(false);
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
  };

  const handleOnClickToday = async () => {
    try {
      setLoad(true);
      setDateSelected(calendarStartValue);
      setIsToday(true);
      const prevDate = Array.from({ length: 15 }, (_, days: number) => moment(moment(calendarStartValue).add(-(days + 1), 'days').toDate())).reverse();
      const nextDate = Array.from({ length: 15 }, (_, days: number) => moment(moment(calendarStartValue).add(days + 1, 'days').toDate()));
      setDates([...prevDate, moment(calendarStartValue), ...nextDate]);
      await getReservation(filteredResults, [...prevDate, moment(calendarStartValue), ...nextDate]);
      const element: HTMLElement | null = document.getElementById(moment(calendarStartValue).format('YYYY-MM-DD'));
      element?.scrollIntoView({ inline: 'center' });
      setLoad(false);
    } catch (e: unknown) {
      if (e instanceof Error) {
        setLoad(false);
        toast.error(e.message || ErrorMessage.default());
        toastErrorRequest(e);
      }
    }
  };

  useEffect(() => {
    if (dateSelected.getDate() === calendarStartValue.getDate()
        && dateSelected.getMonth() === calendarStartValue.getMonth()
        && dateSelected.getFullYear() === calendarStartValue.getFullYear()
    ) {
      setIsToday(true);
    } else {
      setIsToday(false);
    }
  }, [dateSelected]);

  const extractButtonIsActive = (date: Date) => {
    const year = date.getFullYear();
    const monthId = date.getMonth();

    const yearCurrent = NEW_DATE_DEFAULT.getFullYear();
    const monthCurrent = NEW_DATE_DEFAULT.getMonth();

    if (year < 2024 || year > yearCurrent) return false;

    if (year === yearCurrent && monthId > monthCurrent) return false;

    return true;
};

  return (
    <FastDataFowardModal
      id={id}
      onClick={handleOutClick}
      isToday={isToday}
      isOwner={isOwnerPage}
      isFinancialStatementCalendar={isFinancialStatementCalendar}
    >
      <div className="container">
        <div className="content">
          {isFinancialStatementCalendar ? (
            <Calendar
              defaultView={defaultView}
              className="react-calendar"
              onChange={setCalendarValue}
              value={defaultValue || calendarValue}
              prevLabel={<ArrowBackIosIcon />}
              nextLabel={<ArrowForwardIosIcon />}
              next2Label={null}
              prev2Label={null}
              defaultValue={defaultValue}
              tileClassName={({ date }) => (extractButtonIsActive(date) ? 'react-calendar__tile--active' : 'tile-class')}
              onClickDay={(d) => (extractButtonIsActive(d) ? handleOnClickDay(d) : {})}
              onClickMonth={(d) => (extractButtonIsActive(d) ? handleOnClickMonth(d) : {})}
              onClickYear={(d) => (extractButtonIsActive(d) ? handleOnClickYear(d) : {})}
              formatShortWeekday={(locale, value) => ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'][value.getDay()]}
              tileDisabled={({ date }) => !extractButtonIsActive(date)}
              view="year"
              minDate={minDate}
            />
          ) : (
            <Calendar
              defaultView={defaultView}
              className="react-calendar"
              onChange={setCalendarValue}
              value={defaultValue || calendarValue}
              prevLabel={<ArrowBackIosIcon />}
              nextLabel={<ArrowForwardIosIcon />}
              next2Label={null}
              prev2Label={null}
              defaultValue={defaultValue}
              tileClassName="tile-class"
              onClickDay={(day) => handleOnClickDay(day)}
              onClickMonth={(month) => handleOnClickMonth(month)}
              onClickYear={(year) => handleOnClickYear(year)}
              formatShortWeekday={(locale, value) => ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'][value.getDay()]}
            />
          )}
        </div>
        {showTodayButton && (
          <div className="today">
            <button
              type="button"
              onClick={() => handleOnClickToday()}
            >
              Hoje
            </button>
          </div>
        )}
      </div>
    </FastDataFowardModal>
  );
};

FastDataForwardModal.defaultProps = {
  showTodayButton: true,
  defaultView: 'month',
  defaultValue: undefined,
};

export default FastDataForwardModal;
