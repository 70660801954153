import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
import moment from 'moment';

import packageJson from '../../../../package.json';
import { useUser } from '../../../context/UserContext';

import LogoSeazone from '../icons/LogoSeazone';

import {
  Container,
  ButtonLogout,
  Content
} from './styles';

const Footer = () => {
  const { logout } = useUser();
  const navigate = useNavigate();

  const handleLogout = () => {
    const start = localStorage.getItem('startUse');
    const duration = moment.duration(moment().diff(moment(start)));
    const seconds = duration.asSeconds();
    const hours = Math.floor(seconds / 3600);
    const mins = Math.floor((seconds / 60) % 60);
    const secs = Math.floor(seconds % 60);
    ReactGA.event(localStorage.getItem('userDetail') || 'Indefinido', {
      category: localStorage.getItem('userDetail') || 'Indefinido',
      action: `Tempo de acesso ${hours}:${mins}:${secs}`,
    });
    logout();
    navigate('/login');
  };

  return (
    <Container>
      <ButtonLogout type="button" onClick={() => handleLogout()}>
        Sair
      </ButtonLogout>
      <Content>
        <p>Powered by</p>
        <LogoSeazone full onClick={() => {}} />
        <span>v{packageJson.version}</span>
      </Content>
    </Container>
  )

}
export default Footer;
