/* eslint-disable consistent-return */
import { useState, useEffect, useMemo, KeyboardEvent } from 'react';
import { useFormik } from 'formik';
import moment from 'moment';
import { debounce } from 'lodash';

import { FormikProps } from './types';
import { AddressDetail } from '../../../../services/Address/types';
import { AccountOwnerOnboardingProps, PaymentMethod, PipedriveOwnerInfo } from '../../../../services/Onboarding/types';
import { PropertyInformation } from '../../../../context/OnboardingContext/types';

import { getStateMappings } from '../../../../services/Address/states';
import { normalizeCountry } from '../../../../services/Address/countries';
import { postAddress, deleteAddress } from '../../../../services/Address/request';
import { deleteOwner, postOwnerInvoice, deleteOwnerInvoice } from '../../../../services/Owner/request';
import { deleteUser } from '../../../../services/InsertData/request';

import {
  createOwner,
  createOwnerAddress,
  createUser,
} from './services';

import {
  deletePropertyManager,
  deletePropertyRules,
  postPropertyHandover,
  postPropertyManager,
  postPropertyRules,
} from '../../../../services/Onboarding/request';

import { currencyToNumber, formatCNPJToPost, formatCPFtoSubmit, isBrazil } from '../../../../utils/Formatter';
import { useToastErrorMessage } from '../../../../utils/Messages';
import { useToast } from '../../../../context/ToastContext';

import { usePropertyCategoryLocation } from '../../../../hooks/usePropertyCategoryLocation/usePropertyCategoryLocation';
import { useOnboardingHandover } from '../../../../hooks/useOnboarding/useOnboardingHandover';
import { normalizeState } from '../../../../hooks/useIBGE/utils';

import { ownerExists, propertyCodeAlreadyExists } from '../../utils';
import {
  initialValuesForms,
  getOwnerInfosDetails,
  getPropertyInfosDetails,
  getFeesAndComissionsDetails,
  getFinalInfosDetails,
  formatPhoneToSubmit,
  getPartnerSelected,
  getHostSelected,
  getCategorySelected,
} from './utils';

import { handleValidation, getTotalErrors, getValidationSchema } from './validations';

import CardNewOwner from '../Cards/CardNewOwner';
import FormSelected from './FormSelected';
import FormButton from '../../../FormButton';
import { SelectProps } from '../../../Autocomplete/Autocomplete';
import { SelectOption } from '../../../SimpleSelect/SimpleSelect';

import { Form, Content, ButtonContainer } from './styles';
import { UseFormik } from '../../../../utils/Formik/types';
import { sendEmailWithUserCredentials } from '../../../../services/User/request';

const OnboardingForms = () => {
  const [defaultHostSelected, setDefaultHostSelected] = useState<SelectProps>({ optionText: '', optionValue: '' });
  const [defaultPartnerSelected, setDefaultPartnerSelected] = useState<SelectOption>({ valueLabel: '', value: '' });

  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const stateMappings = useMemo(() => getStateMappings(), []);

  const {
    listOwner,
    setListOwner,
    listHost,
    listPartner,
    listOwnerFiltered,
    setListOwnerFiltered,
    setPageVisible,
    dealID,
    setDealID,
    owner,
    infosPipeDrive,
    setInfosPipeDrive,
    resetFormik,
    setResetFormik,
    resetVisibility,
    totalErrors,
    setTotalErrors,
  } = useOnboardingHandover();

  const ownerAlreadyExists = useMemo(() => ownerExists(owner), [owner]);

  const { categoriesLocations } = usePropertyCategoryLocation();

  const handleGetHostSelected = () => {
    const host = getHostSelected(listHost, infosPipeDrive);
    setDefaultHostSelected({
      optionText: host.hostName,
      optionValue: host.hostId,
    });
    return Number(host.hostId);
  };

  const handleGetPartnerSelected = () => {
    const partner = getPartnerSelected(listPartner, infosPipeDrive);
    setDefaultPartnerSelected({
      valueLabel: partner.partnerName,
      value: partner.partnerId,
    });
    return partner.partnerId;
  };

  const handleGetCategorySelected = () => {
    const category = getCategorySelected(categoriesLocations, infosPipeDrive);
    return category.categoryId;
  };

  const handleDeleteAccountOwner = async (ownerDetails: AccountOwnerOnboardingProps  | null) => {
    const mainAddress = ownerDetails?.main_address?.id;
    const userId = ownerDetails?.user_id;
    const ownerId = ownerDetails?.owner_id;

    if (mainAddress && !userId) {
      await deleteAddress(mainAddress);
    } else if (mainAddress && userId && !ownerId) {
      await deleteUser(userId);
      await deleteAddress(mainAddress);
    } else if (mainAddress && userId && ownerId) {
      await deleteOwner(ownerId);
      await deleteUser(userId);
      await deleteAddress(mainAddress);
    }
  };

  type DataDeleteProps = {
    ownerResponse?: AccountOwnerOnboardingProps | null, 
    rulesId?: number
    propertyManagerId?: number, 
    invoiceId?: number,  
    propertyAddressId?: number, 
  };

  const handleDeleteAllDataCreatedIfRequestFail = async (data: DataDeleteProps) => {
    if (data?.rulesId) {
      await deletePropertyRules(data.rulesId);
    }
    if (data?.propertyManagerId) {
      await deletePropertyManager(data.propertyManagerId);
    }
    if (data?.invoiceId) {
      await deleteOwnerInvoice(data?.invoiceId);
    }
    if (data?.propertyAddressId) {
      await deleteAddress(data.propertyAddressId);
    }
    if (data?.ownerResponse) {
      await handleDeleteAccountOwner(data?.ownerResponse);
    }
  };

  const handleCreateAccountOwner = async (formik: UseFormik<any>) => {
    const formValues = formik.values;

    let mainAddress = 0;
    let userId = 0;
    let ownerId = 0;

    try {
      const addressResponse = await createOwnerAddress(formValues, stateMappings);
      mainAddress = addressResponse?.id || 0;

      const userResponse = await createUser(formValues, mainAddress);
      userId = userResponse.id;

      const ownerResponse = await createOwner(formValues, userId, mainAddress);
      ownerId = ownerResponse.id;

      const newOwner: AccountOwnerOnboardingProps = {
        ...owner,
        ...userResponse,
        user_id: userId,
        owner_id: ownerId,
        name: `${userResponse.first_name} ${userResponse.last_name}`,
        cpf: `${userResponse.cpf}`,
        cnpj: `${userResponse.cnpj}`,
        pipedrive_person_id: `${userResponse.pipedrive_person_id}`,
        phone_number1: `${userResponse.phone_number1}`,
        email: `${userResponse.email}`,
        main_address: { ...addressResponse },
      }
      return newOwner;
    } catch (e: any) {
      if (e?.detail?.email?.includes('This field must be unique.')) {
        toast.error('O e-mail inserido já está cadastrado! Tente um e-mail diferente.');
        formik.setFieldError('email', 'O e-mail inserido já está cadastrado! Tente um e-mail diferente.');
        formik.setFieldTouched(
          'email', true, false,
        );
      }
      if (e?.detail?.birth_date?.includes('User age must be over 12 and under 123 years.')) {
        toast.error('A idade inserida deve ser entre 12 e 123 anos. Reveja o campo "Data de nascimento".');
        formik.setFieldError('born', 'A idade inserida deve ser entre 12 e 123 anos. Reveja o campo "Data de nascimento".');
        formik.setFieldTouched(
          'born', true, false,
        );
      }

      if (e instanceof Error) {
        if (mainAddress && !userId) {
          await deleteAddress(mainAddress);
        } else if (mainAddress && userId && !ownerId) {
          await deleteUser(userId);
          await deleteAddress(mainAddress);
        } else if (mainAddress && userId && ownerId) {
          await deleteUser(userId);
          await deleteOwner(ownerId);
          await deleteAddress(mainAddress);
        }
        toastErrorRequest(e);
      }
      return null;
    }
  };

  const handleCreatePropertyAddress = async (propertyAddress: AddressDetail) => {
    const address = await postAddress({
      city: propertyAddress.city,
      state: propertyAddress.state,
      number: propertyAddress.number,
      street: propertyAddress.street,
      country: normalizeCountry(propertyAddress.country),
      complement: propertyAddress.complement,
      condominium: propertyAddress.condominium,
      postal_code: propertyAddress.postal_code,
      neighborhood: propertyAddress.neighborhood,
    });
    return address;
  };

  const handleCreateInvoice = async (formik: UseFormik<any>, propertyAddress: AddressDetail, ownerResponse: AccountOwnerOnboardingProps | null) => {
    const formValues = formik.values;
    const ownerCountryIsBrazil = isBrazil(`${formValues?.owner_address_country || 'Brasil'}`);

    const invoice = await postOwnerInvoice({
      cpf: formValues.typePerson === 'individual' ? formatCPFtoSubmit(formValues.cpf) : undefined,
      cnpj: formValues.typePerson !== 'individual' ? formatCNPJToPost(formValues.cnpj) : undefined,
      address: ownerCountryIsBrazil ? formValues.owner_address_street : propertyAddress.street,
      address_number: ownerCountryIsBrazil ? formValues.owner_address_number : propertyAddress.number,
      city: ownerCountryIsBrazil ? formValues.owner_address_city : propertyAddress.city,
      complement: ownerCountryIsBrazil ? formValues.owner_address_complement : propertyAddress.complement,
      state: ownerCountryIsBrazil ? formValues.owner_address_state : propertyAddress.state,
      district: ownerCountryIsBrazil ? formValues.owner_address_neighborhood : propertyAddress.neighborhood,
      postal_code: ownerCountryIsBrazil ? formValues.owner_address_postal_code : propertyAddress.postal_code,
      phone_number: formValues.phone,
      email: formValues.email,
      user: ownerAlreadyExists ? owner?.user_id : ownerResponse?.user_id,
      invoice_entity_name: `${formValues.name} ${formValues.lastname}`,
    });
    return invoice;
  };

  const handleCreatePropertyManager = async (formik: UseFormik<any>, propertyAddress: AddressDetail, propertyDetails: PropertyInformation, ownerResponse: AccountOwnerOnboardingProps | null) => {
    const formValues = formik.values;

    const property = await postPropertyManager({
      address: propertyAddress.id,
      host: Number(formValues.host),
      code: `${propertyDetails?.propertyCode}`,
      comission_fee: formValues.commissions ? `${formValues.commissions / 100}` : undefined,
      property_type: propertyDetails?.propertyType,
      bedroom_quantity: propertyDetails?.bedroomsAmount,
      category_location: Number(propertyDetails?.category),
      contract_start_date: moment().format('YYYY-MM-DD'),
      balance_discount_rate: formValues.paymentMethod === 'Discount_Rate' ? `${formValues.valueBalanceDiscountRate / 100}` : '0',
      status: 'Onboarding',
      owners: [ownerAlreadyExists ? `${owner?.owner_id}` : `${ownerResponse?.owner_id}`],
      owner: ownerAlreadyExists ? owner?.owner_id : ownerResponse?.owner_id,
      host_reservation_comission_fee: formik.values.plan === 'Digital_Management' ? 0 : formik.values.hostReservationComissionFee / 100,
      host_cleaning_comission_fee: formik.values.plan === 'Digital_Management' ? 0 : 1,
    });

    return property; 
  };

  const handleCreatePropertyRules = async (propertyId: number) => {
    const rules = await postPropertyRules({
      allow_pet: false,
      property: propertyId,
      check_in_time: '11:00',
      check_out_time: '15:00',
      events_permitted: false,
      smoking_permitted: false,
      suitable_for_babies: false,
      suitable_for_children: false,
    });
    return rules;
  };

  const handleCreateProperyHandover = async(formik: UseFormik<any>, rulesId: number, propertyId: number, propertyDetails: PropertyInformation) => {
    const formValues = formik.values;

    const propertyHandover = await postPropertyHandover({
      rules: rulesId,
      plan: formValues.plan,
      property: propertyId,
      pipedrive_deal_id: dealID || undefined,
      comment: propertyDetails?.comment,
      implatation_items_description: formValues.description,
      onboarding_contact_name: propertyDetails?.onboardingName,
      onboarding_contact_phonenumber: propertyDetails?.onboardingPhone,
      property_area_size_m2: propertyDetails?.propertyAreaSizeM2,
      payment_method: formValues.paymentMethod as PaymentMethod,
      bed_linen_photo: null,
      implantation_fee_total_value: currencyToNumber(formValues?.totalImplantationFee),
      payment_installments: formValues.paymentMethod === 'Installments' ? formValues.qtdInstallments : 0,
    });
    return propertyHandover;
  };

  const handleUpdateStates = (ownerResponse: AccountOwnerOnboardingProps | null, propertyDetails: PropertyInformation) => {
    if (ownerAlreadyExists) {
      const owners = listOwner.map((item) => ({
        ...item,
        property_codes: `${item.owner_id}` === `${owner.owner_id}` ? [...item.property_codes, propertyDetails?.propertyCode].sort() : item.property_codes,
      }));

      const ownersFiltered = listOwnerFiltered.map((item) => ({
        ...item,
        property_codes: `${item.owner_id}` === `${owner.owner_id}` ? [...item.property_codes, propertyDetails?.propertyCode].sort() : item.property_codes,
      }));

      setListOwner(owners);
      setListOwnerFiltered(ownersFiltered);
    } else if (ownerResponse) {
      const newOwner: AccountOwnerOnboardingProps = {
        ...ownerResponse,
        property_codes: [propertyDetails?.propertyCode],
      };
      setListOwner([...listOwner, newOwner]);
      setListOwnerFiltered([newOwner]);
    }

    setDealID('');
  };

  const initialValues: FormikProps = {
    ...initialValuesForms,
    ...getOwnerInfosDetails(infosPipeDrive, owner, stateMappings),
    ...getPropertyInfosDetails(infosPipeDrive, stateMappings),
    ...getFeesAndComissionsDetails(infosPipeDrive),
    ...getFinalInfosDetails(infosPipeDrive, ownerAlreadyExists),
  };

  const formik = useFormik({
    initialValues,
    validationSchema: getValidationSchema(ownerAlreadyExists),
    onSubmit: async (formValues) => {
      const propertyExists = propertyCodeAlreadyExists(formValues.propertyCode, listOwner);
      if (propertyExists) {
        toast.error('Esse código de imóvel já existe e está ativo!');
        formik.setFieldError('propertyCode', 'Esse código de imóvel já existe e está ativo!');
        formik.setFieldTouched(
          'propertyCode', true, false,
        );
        return;
      }

      let ownerResponse: AccountOwnerOnboardingProps  | null = null;
      if (!ownerAlreadyExists) { ownerResponse = await handleCreateAccountOwner(formik); }
      if (!ownerAlreadyExists && !ownerResponse) return;
      if (ownerAlreadyExists && !owner) return;

      const propertyDetails: PropertyInformation = {
        propertyCode: formValues.propertyCode,

        inCondominium: formValues.inCondominium,
        condominium: formValues.inCondominium ? formValues.condominium : '',

        city: formValues.property_address_city,
        neighborhood: formValues.property_address_neighborhood,
        number: formValues.property_address_number,
        postal_code: formValues.property_address_postal_code,
        state: normalizeState(stateMappings, formValues.property_address_state || ''),
        street: formValues.property_address_street,
        complement: formValues.property_address_complement,

        category: formValues.categoryLocationId,
        bedroomsAmount: formValues.bedroomsAmount,
        propertyAreaSizeM2: formValues.propertyAreaSizeM2,
        propertyType: formValues.propertyType,

        comment: formValues.comment,

        isOwnerContact: formValues.isOwnerContact,
        onboardingName: formValues.isOwnerContact ? '' : formValues.onboardingName,
        onboardingPhone: formValues.isOwnerContact ? '' : formatPhoneToSubmit(formValues.onboardingPhone),
      };

      let propertyAddress: AddressDetail = {
        id: -1,
        country: 'Brasil',
        city: propertyDetails?.city,
        state: propertyDetails?.state,
        street: propertyDetails?.street,
        number: propertyDetails?.number,
        complement: propertyDetails?.complement,
        condominium: propertyDetails?.condominium,
        postal_code: propertyDetails?.postal_code,
        neighborhood: propertyDetails?.neighborhood,
      };

      try {
        propertyAddress = await handleCreatePropertyAddress(propertyAddress);
      
        await handleCreateInvoice(formik, propertyAddress, ownerResponse);

        try {
          const property = await handleCreatePropertyManager(formik, propertyAddress, propertyDetails, ownerResponse);

          try {
            const rules = await handleCreatePropertyRules(property.id as number);
           
            try {
              await handleCreateProperyHandover(formik, rules.id, property.id, propertyDetails);
              handleUpdateStates(ownerResponse, propertyDetails);
              if (!ownerAlreadyExists && ownerResponse?.user_id) {
                const sendEmailWithUserCredentialsPayload = {
                  property_code: propertyDetails.propertyCode,
                };
                await sendEmailWithUserCredentials(ownerResponse.user_id, sendEmailWithUserCredentialsPayload);
              }
              setPageVisible('Page_Final');
            } catch (e: any) {
              if (e?.detail?.pipedrive_deal_id?.includes('A valid integer is required.')) {
                toast.error('O campo "DEAL ID" precisa ser um número com no mínimo 5 caracteres.');
              } else if (e instanceof Error) {
                toastErrorRequest(e);
              }
              await handleDeleteAllDataCreatedIfRequestFail({
                rulesId: rules.id,
                propertyManagerId: property.id,
                propertyAddressId: propertyAddress.id as number, 
                ownerResponse,
              });
            }
          } catch (e: unknown) {
            if (e instanceof Error) {
              toastErrorRequest(e);
            }
            await handleDeleteAllDataCreatedIfRequestFail({
              propertyManagerId: property.id,
              propertyAddressId: propertyAddress.id as number,
              ownerResponse,
            });
          }
        } catch (e: any) {
          if (e?.detail?.pipedrive_deal_id?.includes('A valid integer is required.')) {
            toast.error('O campo "DEAL ID" precisa ser um número com no mínimo 5 caracteres.');
          } else if (e?.detail?.category_location?.includes('This field may not be null.')) {
            toast.error('O campo "Categoria" não foi preenchido.');
          } else if (e?.detail?.non_field_errors?.includes('This property code already exists and is active')) {
            toast.error('Esse código de imóvel já existe e está ativo!');
          } else if (e?.detail?.onboarding_contact_phonenumber?.includes('The entered phone number is not valid.')) {
            toast.error('O número de telefone não é válido.');
          } else if (e instanceof Error) {
            toastErrorRequest(e);
          }
          await handleDeleteAllDataCreatedIfRequestFail({
            propertyAddressId: propertyAddress.id as number,
            ownerResponse,
          });
        }
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  const ownerCountryIsBrazil = useMemo(() => {
    if (formik.values.isForeignUser && isBrazil(`${formik.values.owner_address_country}`)) {
      formik.setFieldValue('owner_address_country', '');
    } else if (!formik.values.isForeignUser && !isBrazil(`${formik.values.owner_address_country}`)) {
      formik.setFieldValue('owner_address_country', '');
    }
    return !formik.values.isForeignUser;
  }, [formik.values.isForeignUser]);

  useEffect(() => {
    if (![''].includes(`${formik.values.owner_address_country}`)) {
      formik.setFieldValue('isForeignUser', !isBrazil(`${formik.values.owner_address_country}`));
    }
  }, [formik.values.owner_address_country]);

  useEffect(() => {
    formik.setValues({
      ...initialValues,
      ...getOwnerInfosDetails(infosPipeDrive, owner, stateMappings),
      ...getPropertyInfosDetails(infosPipeDrive, stateMappings),
      ...getFeesAndComissionsDetails(infosPipeDrive),
      ...getFinalInfosDetails(infosPipeDrive, ownerAlreadyExists),
      host: handleGetHostSelected(),
      indicationName: formik.values.isPartnerIndication ? handleGetPartnerSelected() : '',
      categoryLocationId: handleGetCategorySelected(),
    });
  }, [infosPipeDrive, owner]);

  const disabledSaveButton = useMemo(() => {
    const errorsCounter = Object.values(totalErrors);
    const errorExists = errorsCounter.some((error) => !['undefined', 'null', '0'].includes(`${error}`));
    return errorExists;
  }, [totalErrors]);

  const handleGetErrors = async () => {
    await handleValidation(formik);
    const errors = await getTotalErrors({ formik, ownerCountryIsBrazil, ownerAlreadyExists });
    setTotalErrors(errors);
  };

  const debouncedGetTotalErrors = debounce(handleGetErrors, 1000);

  useEffect(() => {
    debouncedGetTotalErrors();
  }, [formik.values]);


  useEffect(() => {
    if (resetFormik) {
      formik.resetForm();
      formik.setValues(initialValuesForms);
      setInfosPipeDrive({} as PipedriveOwnerInfo);
      resetVisibility();
      setResetFormik(false);
    }
  }, [resetFormik]);

  const handleKeyDown = (event: KeyboardEvent | any) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <Form
      onKeyDown={handleKeyDown}
      onSubmit={formik.handleSubmit}
    >
      <CardNewOwner formik={formik} />
      <Content>
        <FormSelected
          formik={formik}
          ownerCountryIsBrazil={ownerCountryIsBrazil}
          ownerAlreadyExists={ownerAlreadyExists}
          defaultPartnerSelected={defaultPartnerSelected}
          defaultHostSelected={defaultHostSelected}
        />
      </Content>
      <ButtonContainer>
        <FormButton type="submit" disable={disabledSaveButton || formik.isSubmitting}>Salvar</FormButton>
      </ButtonContainer>
    </Form>
  )
}

export default OnboardingForms;
