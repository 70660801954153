import styled from 'styled-components';

const MAX_SIZE_MOBILE = 900;

export const Container = styled.div`
  display: none;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 998;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    display: flex;
    width: 100%;
    height: 55px;
    justify-content: space-between;
    align-items: center;
    padding: 0px 24px;
    border: 1px solid #d9dcdf;
    background: ${({ theme }) => theme.palette.white.main.hex()};
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MenuIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Margin = styled.div`
  width: 100%;
  display: none;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    display: flex;
  }
  
  @media (max-width: 786px) {
    height: 1px;
    margin: 2rem 0;
  }
`;
