/* eslint-disable no-restricted-globals */
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { patchPropertyAmenities, postPropertyAmenities } from '../../../services/InsertData/request';
import { PostPropertyAmenitiesObject } from '../../../services/InsertData/types';
import FormButton from '../../FormButton';
import FormAddPropertyAmenities from './FormAddPropertyAmenities/FormAddPropertyAmenities';
import {
  ButtonsContainer, Container, Divider, Form, Title,
} from './styles';

import { useToast } from '../../../context/ToastContext';
import { useToastErrorMessage } from '../../../utils/Messages';
import { usePropertyAmenities } from '../../../hooks/usePropertyAmenities/usePropertyAmenities';
import { handleUpdateFormPropertyAmenities } from '../../../context/InsertPropertyAmenitiesContext/utils';

interface PropertyAmenitiesFormikValuesType {
  id: number;
  property: number,
}

const validation = Yup.object().shape({
  property: Yup.number().required('Campo Obrigatório').min(0, 'Selecione uma propriedade'),
});

const InsertPropertyAmenitiesData = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const toastErrorRequest = useToastErrorMessage();

  const {
    allAmenities,
  } = usePropertyAmenities();

  const formik = useFormik<PropertyAmenitiesFormikValuesType>({
    initialValues: {
      id: -1,
      property: -1,
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      event?.preventDefault();

      try {
        const propertyAmenities: PostPropertyAmenitiesObject = {
          ...handleUpdateFormPropertyAmenities(allAmenities),
          property: Number(values.property),
        }; // Objeto enviado a API /property/amenities/
        if (values.id === -1) {
          await postPropertyAmenities(propertyAmenities);
          toast.success('Comodidades criadas com sucesso!');
        } else {
          await patchPropertyAmenities(values.id, propertyAmenities);
          toast.success('Comodidades alterdas com sucesso!');
        }
        navigate('/home');
        formik.resetForm();
      } catch (e: unknown) {
        if (e instanceof Error) {
          toastErrorRequest(e);
        }
      }
    },
  });

  return (
    <Container>
      <div className="header">
        <div>
          <Title>Comodidades das propriedades</Title>
        </div>
      </div>
      <div className="divider">
        <Divider />
      </div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="formContainer">
          <FormAddPropertyAmenities formik={formik} />
        </div>
        <ButtonsContainer>
          <div>
            <FormButton
              type="button"
              variant="outlined"
              onClick={() => { navigate('/home'); }}
            >
              Cancelar
            </FormButton>
            <FormButton type="submit" disable={formik.values.property === 0}>Salvar</FormButton>
          </div>
        </ButtonsContainer>
      </Form>
    </Container>
  );
};

export default InsertPropertyAmenitiesData;
