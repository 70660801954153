import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const NavItemDiv = styled.div<{ shouldBeVisible: boolean }>`
  display: ${({ shouldBeVisible }) => (shouldBeVisible ? 'flex' : 'none')};
  height: 32px;
  padding: 6px 8px;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;

  p {
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &:hover {
    background: #2D3251;
    svg path {
      stroke: #0055FF !important;
    }
  }
`;

export const NavItemLink = styled(Link)<{ shouldBeVisible: boolean }>`
  display: ${({ shouldBeVisible }) => (shouldBeVisible ? 'flex' : 'none')};
  height: 32px;
  padding: 6px 8px;
  border-radius: 4px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  text-decoration: none;

  &.expanded {
    display: ${({ shouldBeVisible }) => (shouldBeVisible ? 'flex' : 'none')};
    padding-left: 24px;
  }

  &.collapsed {
    display: none;
  }

  &:hover {
    background: #2D3251;
  }

  p {
    color: #FFF;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
`;

export const Title = styled.p`
  color: #FFF;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`;
