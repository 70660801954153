import { Container } from './styles';

interface ILogoUniverseHost {
  color?: 'white' | 'blue',
};

const LogoUniverseHost = ({ color = 'white' }: ILogoUniverseHost) => (
  <Container>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.125 12.3096C3.81388 12.8245 3 13.5407 3 14.3333C3 15.8987 6.17121 17.1666 10.0833 17.1666C13.9955 17.1666 17.1667 15.8987 17.1667 14.3333C17.1667 13.5407 16.3528 12.8245 15.0417 12.3096M10.0845 3C8.95733 3 7.87633 3.44777 7.0793 4.2448C6.28227 5.04183 5.8345 6.12283 5.8345 7.25C5.8345 8.25513 6.04842 8.91317 6.63138 9.64062L10.0845 13.625L13.5376 9.64062C14.1206 8.91317 14.3345 8.25513 14.3345 7.25C14.3345 6.12283 13.8867 5.04183 13.0897 4.2448C12.2927 3.44777 11.2117 3 10.0845 3ZM10.7917 7.24972C10.7917 7.64092 10.4746 7.95805 10.0834 7.95805C9.69216 7.95805 9.37503 7.64092 9.37503 7.24972C9.37503 6.85851 9.69216 6.54138 10.0834 6.54138C10.4746 6.54138 10.7917 6.85851 10.7917 7.24972Z" stroke={color === 'blue' ? '#5274D8' : color} strokeWidth="1.78947" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </Container>
);

LogoUniverseHost.defaultProps = {
  color: 'white',
};

export default LogoUniverseHost;
