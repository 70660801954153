import { UniversesOptions, useMenuLateral } from "../../../context/MenuLateral";

import { useUser } from "../../../context/UserContext";
import { useRequestSupportModal } from "../../../hooks/useRequestModal";
import { ActivitiesProps } from "../../../pages/Home/context/FavoriteActivitiesContext";

import LogoUniverseFinances from "../icons/Universes/LogoUniverseFinances";
import LogoUniverseHost from "../icons/Universes/LogoUniverseHost";
import LogoUniverseOperational from "../icons/Universes/LogoUniverseOperational";
import LogoUniverseOwner from "../icons/Universes/LogoUniverseOwner";
import LogoUniversePartner from "../icons/Universes/LogoUniversePartner";

import NavItemComponent from "../NavItemComponent";

const useUniverseMappings = () => {
  const { categoryActive } = useMenuLateral();
  const { userInformation } = useUser();
  const { setIsOpenedRequestHostOpsModal } = useRequestSupportModal();

  type UniversesNavItemComponentOptions = Record<UniversesOptions, JSX.Element>;

  const universeMappingsBackoffice: UniversesNavItemComponentOptions = {
    'Operacional': (
      <>
        <NavItemComponent
          title="Multicalendar"
          path="/multicalendar"
          permissions={['Admin', 'SeazoneAdministrative', 'Attendant']}
        />

        <NavItemComponent
          title="Hóspede"
          permissions={['Admin', 'Attendant']}
        />
        <NavItemComponent
          title="Reservas para Completar"
          path="/compreservas"
          permissions={['Admin', 'Attendant']}
          className={categoryActive.title === 'Hóspede' && categoryActive.actived ? 'expanded' : 'collapsed'}
        />
        <NavItemComponent
          title="Vouchers Enviados"
          path="/vouchersenviados"
          permissions={['Admin', 'Attendant']}
          className={categoryActive.title === 'Hóspede' && categoryActive.actived ? 'expanded' : 'collapsed'}
        />

        <NavItemComponent
          title="Anúncio"
          permissions={['Admin', 'SeazoneOnboarding']}
        />
        <NavItemComponent
          title="Novo Listing"
          path="/inserirdados/novo-listing"
          permissions={['Admin', 'SeazoneOnboarding']}
          className={categoryActive.title === 'Anúncio' && categoryActive.actived ? 'expanded' : 'collapsed'}
        />
        <NavItemComponent
          title="Editar Listing"
          path="/editardados/listing"
          permissions={['Admin', 'SeazoneOnboarding']}
          className={categoryActive.title === 'Anúncio' && categoryActive.actived ? 'expanded' : 'collapsed'}
        />

        <NavItemComponent
          title="Implantação"
          permissions={['Admin', 'SeazoneOnboarding', 'Attendant']}
        />
        <NavItemComponent
          title="Pesquisar Propriedade"
          path="/propriedades"
          permissions={['Admin', 'SeazoneOnboarding', 'Attendant']}
          className={categoryActive.title === 'Implantação' && categoryActive.actived ? 'expanded' : 'collapsed'}
        />
        <NavItemComponent
          title="Onboarding"
          path="/onboarding"
          permissions={['Admin', 'SeazoneOnboarding']}
          className={categoryActive.title === 'Implantação' && categoryActive.actived ? 'expanded' : 'collapsed'}
        />
        <NavItemComponent
          title="Editar Propriedade"
          path="/editardados/propriedade"
          permissions={['Admin', 'SeazoneOnboarding']}
          className={categoryActive.title === 'Implantação' && categoryActive.actived ? 'expanded' : 'collapsed'}
        />
        <NavItemComponent
          title="Mudança de Status"
          path="/inserirdados/mudar-status-imovel"
          permissions={['Admin', 'SeazoneOnboarding']}
          className={categoryActive.title === 'Implantação' && categoryActive.actived ? 'expanded' : 'collapsed'}
        />
        <NavItemComponent
          title="Comodidades"
          path="/inserirdados/property-amenities"
          permissions={['Admin', 'SeazoneOnboarding']}
          className={categoryActive.title === 'Implantação' && categoryActive.actived ? 'expanded' : 'collapsed'}
        />
        <NavItemComponent
          title="Regras"
          path="/inserirdados/regras"
          permissions={['Admin', 'SeazoneOnboarding']}
          className={categoryActive.title === 'Implantação' && categoryActive.actived ? 'expanded' : 'collapsed'}
        />
      </>
    ),
    'Financeiro': (
      <>
        <NavItemComponent
          title="Multicalendar"
          path="/multicalendar"
          permissions={['Admin', 'SeazoneAdministrative', 'Attendant']}
        />

        <NavItemComponent
          title="Despesas"
          path="/despesas"
          permissions={['Admin', 'SeazoneAdministrative', 'SeazoneOnboarding']}
          verifyHostOps
        />

        <NavItemComponent
          title="Danos de Hóspede"
          path="/danosdehospede"
          permissions={['Admin', 'Attendant', 'SeazoneAdministrative', 'SeazoneOnboarding']}
        />

        <NavItemComponent
          title="Lista de NF"
          path="/listadenfs"
          permissions={['Admin', 'SeazoneAdministrative']}
        />

        <NavItemComponent
          title="Lista de Repasses"
          permissions={['Admin', 'SeazoneAdministrative']}
        />
        <NavItemComponent
          title="Proprietário"
          path="/listaderepassesproprietario"
          permissions={['Admin', 'SeazoneAdministrative']}
          className={categoryActive.title === 'Lista de Repasses' && categoryActive.actived ? 'expanded' : 'collapsed'}
        />
        <NavItemComponent
          title="Anfitrião"
          path="/listaderepassesanfitriao"
          permissions={['Admin', 'SeazoneAdministrative']}
          className={categoryActive.title === 'Lista de Repasses' && categoryActive.actived ? 'expanded' : 'collapsed'}
        />

        <NavItemComponent
          title="Fechamento"
          permissions={['Admin', 'SeazoneAdministrative']}
        />
        <NavItemComponent
          title="Imóvel"
          path="/fechamentoimovel"
          permissions={['Admin', 'SeazoneAdministrative']}
          className={categoryActive.title === 'Fechamento' && categoryActive.actived ? 'expanded' : 'collapsed'}
        />
        <NavItemComponent
          title="Anfitrião"
          path="/fechamentoanfitriao"
          permissions={['Admin', 'SeazoneAdministrative']}
          className={categoryActive.title === 'Fechamento' && categoryActive.actived ? 'expanded' : 'collapsed'}
        />

        <NavItemComponent
          title="Painel Gerenciamento"
          path="/painelgerenciamento"
          permissions={['Admin', 'SeazoneAdministrative', 'SeazoneOnboarding', 'Attendant']}
        />
      </>
    ),
    'Proprietário': (
      <>
        <NavItemComponent
          title="Lista"
          path="/proprietarios"
          permissions={['Admin', 'SeazoneOnboarding']}
        />

        <NavItemComponent
          title="Editar"
          path="/editardados/proprietario"
          permissions={['Admin', 'SeazoneOnboarding']}
        />

        <NavItemComponent
          title="Onboarding"
          path="/onboarding"
          permissions={['Admin', 'SeazoneOnboarding']}
        />

        <NavItemComponent
          title="Perspectiva do Proprietário"
          path="/painel-perspectiva-do-proprietario"
          permissions={['Admin', 'SeazoneAdministrative', 'SeazoneOnboarding']}
        />
      </>
    ),
    'Anfitrião': (
      <>
        <NavItemComponent
          title="Controle"
          path="/controle"
          permissions={['Admin']}
        />

        <NavItemComponent
          title="Pesquisar"
          path="/anfitrioes"
          permissions={['Admin', 'SeazoneOnboarding']}
        />

        <NavItemComponent
          title="Inserir"
          path="/inserirdados/anfitriao"
          permissions={['Admin', 'SeazoneOnboarding']}
        />

        <NavItemComponent
          title="Editar"
          path="/editardados/anfitriao"
          permissions={['Admin', 'SeazoneOnboarding']}
        />

        <NavItemComponent
          title="Troca de Anfitrião do Imóvel"
          path="/editardados/mudar-anfitriao"
          permissions={['Admin', 'SeazoneOnboarding']}
        />

        <NavItemComponent
          title="Danos de Hóspede"
          path="/danosdehospede"
          permissions={['Admin', 'Attendant', 'SeazoneAdministrative', 'SeazoneOnboarding']}
        />
      </>
    ),
    'Parceiros': (
      <>
        <NavItemComponent
          title="Editar Parceiro"
          path="/editardados/parceiro"
          permissions={['Admin', 'SeazoneOnboarding']}
        />

        <NavItemComponent
          title="Novos Dados"
          permissions={['Admin', 'SeazoneOnboarding']}
        />
        <NavItemComponent
          title="Parceiro"
          path="/inserirdados/parceiro"
          permissions={['Admin', 'SeazoneOnboarding']}
          className={categoryActive.title === 'Novos Dados' && categoryActive.actived ? 'expanded' : 'collapsed'}
        />

        <NavItemComponent
          title="Condomínio B2B"
          path="/inserirdados/condominio-b2b"
          permissions={['Admin', 'SeazoneOnboarding']}
          className={categoryActive.title === 'Novos Dados' && categoryActive.actived ? 'expanded' : 'collapsed'}
        />

        <NavItemComponent
          title="Inserir SPOT para indicação"
          path="/inserirdados/spot"
          permissions={['Admin', 'SeazoneOnboarding']}
        />
      </>
    )
  }

  const universeMappingsHost: UniversesNavItemComponentOptions = {
    'Operacional': (
      <>
        <NavItemComponent
          title="Controle"
          path="/controle"
          permissions={['Host']}
        />

        <NavItemComponent
          title="Multicalendar"
          path="/multicalendar"
          permissions={['Host']}
        />

        <NavItemComponent
          title="Danos de Hóspede"
          path="/danosdehospede"
          permissions={['Host']}
        />

        <NavItemComponent
          title="Pesquisar Propriedade"
          path="/propriedades"
          permissions={['Host']}
        />

        <NavItemComponent
          title="Criação de Subconta"
          permissions={['Host']}
          disableDropIcon
          verifyHostOps
          handleOnClick={() => setIsOpenedRequestHostOpsModal(true)}
        />

        <NavItemComponent
          title="Formulário Primeira Visita"
          permissions={['Host']}
          disableDropIcon
          handleOnClick={() => { window.open("https://app.pipefy.com/public/form/I96wBxVW", "_blank"); }}
        />
      </>
    ),
    'Financeiro': (
      <>
        <NavItemComponent
          title="Pedido de Reembolso"
          path="/reembolso"
          permissions={['Host']}
        />
        <NavItemComponent
          title="Dashboard"
          path="/dashboard"
          permissions={['Host']}
          verifyHostOps
        />
      </>
    ),
    'Proprietário': (
      <>
      </>
    ),
    'Anfitrião': (
      <>
        <NavItemComponent
          title="Minhas Informações"
          path="/minhasinformacoes"
          permissions={['Host']}
        />
      </>
    ),
    'Parceiros': (
      <>
        <NavItemComponent
          title="Programa de Indicações"
          path="/parceiros/painel"
          permissions={['Partner']}
          verifyHostOps
        />
      </>
    )
  }

  type UniversesActivitiesOptions = Record<UniversesOptions, Array<Omit<ActivitiesProps, 'universe'>>>;

  const activitiesMappingsBackoffice: UniversesActivitiesOptions = {
    'Operacional': [
      {
        title: "Multicalendar",
        path: "/multicalendar",
        permissions: ['Admin', 'SeazoneAdministrative', 'Attendant']
      },
      {
        title: "Reservas para Completar",
        path: "/compreservas",
        permissions: ['Admin', 'Attendant']
      }, {
        title: "Vouchers Enviados",
        path: "/vouchersenviados",
        permissions: ['Admin', 'Attendant']
      },
      {
        title: "Novo Listing",
        path: "/inserirdados/novo-listing",
        permissions: ['Admin', 'SeazoneOnboarding']
      }, {
        title: "Editar Listing",
        path: "/editardados/listing",
        permissions: ['Admin', 'SeazoneOnboarding']
      },
      {
        title: "Pesquisar Propriedade",
        path: "/propriedades",
        permissions: ['Admin', 'SeazoneOnboarding', 'Attendant']

      }, {
        title: "Onboarding",
        path: "/onboarding",
        permissions: ['Admin', 'SeazoneOnboarding']
      }, {
        title: "Editar Propriedade",
        path: "/editardados/propriedade",
        permissions: ['Admin', 'SeazoneOnboarding']
      }, {
        title: "Mudança de Status",
        path: "/inserirdados/mudar-status-imovel",
        permissions: ['Admin', 'SeazoneOnboarding']
      }, {
        title: "Comodidades",
        path: "/inserirdados/property-amenities",
        permissions: ['Admin', 'SeazoneOnboarding'],
      }, {
        title: "Regras",
        path: "/inserirdados/regras",
        permissions: ['Admin', 'SeazoneOnboarding']
      },
    ],
    'Financeiro': [
      {
        title: "Multicalendar",
        path: "/multicalendar",
        permissions: ['Admin', 'SeazoneAdministrative', 'Attendant']
      },
      {
        title: "Despesas",
        path: "/despesas",
        permissions: ['Admin', 'SeazoneAdministrative', 'SeazoneOnboarding']
      },
      {
        title: "Danos de Hóspede",
        path: "/danosdehospede",
        permissions: ['Admin', 'Attendant', 'SeazoneAdministrative', 'SeazoneOnboarding']
      }, {
        title: "Lista de NF",
        path: "/listadenfs",
        permissions: ['Admin', 'SeazoneAdministrative']
      }, {
        title: "Repasses > Proprietário",
        path: "/listaderepassesproprietario",
        permissions: ['Admin', 'SeazoneAdministrative']
      }, {
        title: "Repasses > Anfitrião",
        path: "/listaderepassesanfitriao",
        permissions: ['Admin', 'SeazoneAdministrative']
      },
      {
        title: "Fechamento > Imóvel",
        path: "/fechamentoimovel",
        permissions: ['Admin', 'SeazoneAdministrative']
      }, {
        title: "Fechamento > Anfitrião",
        path: "/fechamentoanfitriao",
        permissions: ['Admin', 'SeazoneAdministrative']
      }, {
        title: "Painel Gerenciamento",
        path: "/painelgerenciamento",
        permissions: ['Admin', 'SeazoneAdministrative', 'SeazoneOnboarding', 'Attendant']
      }
    ],
    'Proprietário': [
      {
        title: "Lista",
        path: "/proprietarios",
        permissions: ['Admin', 'SeazoneOnboarding']
      },
      {
        title: "Editar",
        path: "/editardados/proprietario",
        permissions: ['Admin', 'SeazoneOnboarding']
      },
      {
        title: "Onboarding",
        path: "/onboarding",
        permissions: ['Admin', 'SeazoneOnboarding']
      },
      {
        title: "Perspectiva do Proprietário",
        path: "/painel-perspectiva-do-proprietario",
        permissions: ['Admin', 'SeazoneAdministrative', 'SeazoneOnboarding']
      },
    ],
    'Anfitrião': [
      {
        title: "Controle",
        path: "/controle",
        permissions: ['Admin']
      },
      {
        title: "Pesquisar",
        path: "/anfitrioes",
        permissions: ['Admin', 'SeazoneOnboarding']
      },
      {
        title: "Inserir",
        path: "/inserirdados/anfitriao",
        permissions: ['Admin', 'SeazoneOnboarding']
      },
      {
        title: "Editar",
        path: "/editardados/anfitriao",
        permissions: ['Admin', 'SeazoneOnboarding']
      },
      {
        title: "Troca de Anfitrião do Imóvel",
        path: "/editardados/mudar-anfitriao",
        permissions: ['Admin', 'SeazoneOnboarding']
      },
      {
        title: "Danos de Hóspede",
        path: "/danosdehospede",
        permissions: ['Admin', 'Attendant', 'SeazoneAdministrative', 'SeazoneOnboarding']
      },
    ],
    'Parceiros': [
      {
        title: "Editar Parceiro",
        path: "/editardados/parceiro",
        permissions: ['Admin', 'SeazoneOnboarding']
      },
      {
        title: "Parceiro",
        path: "/inserirdados/parceiro",
        permissions: ['Admin', 'SeazoneOnboarding']
      },
      {
        title: "Condomínio B2B",
        path: "/inserirdados/condominio-b2b",
        permissions: ['Admin', 'SeazoneOnboarding']
      },
      {
        title: "Inserir SPOT para indicação",
        path: "/inserirdados/spot",
        permissions: ['Admin', 'SeazoneOnboarding']
      },
    ]
  }

  const activitiesMappingsHost: UniversesActivitiesOptions = {
    'Operacional': [
      {
        title: "Controle",
        path: "/controle",
        permissions: ['Host']
      },
      {
        title: "Multicalendar",
        path: "/multicalendar",
        permissions: ['Host']
      },
      {
        title: "Danos de Hóspede",
        path: "/danosdehospede",
        permissions: ['Host']
      },
      {
        title: "Pesquisar Propriedade",
        path: "/propriedades",
        permissions: ['Host']
      },
      {
        title: "Criação de Subconta",
        path: "/abrir-modal-subconta",
        verifyHostOps: true
      },
      {
        title: "Formulário Primeira Visita",
        path: "https://app.pipefy.com/public/form/I96wBxVW",
        permissions: ['Host']
      },
    ],
    'Financeiro': [
      {
        title: "Pedido de Reembolso",
        path: "/reembolso",
        permissions: ['Host']
      },
      {
        title: "Dashboard",
        path: "/dashboard",
        verifyHostOps: true
      },
    ],
    'Proprietário': [],
    'Anfitrião': [
      {
        title: "Minhas Informações",
        path: "/minhasinformacoes",
        permissions: ['Host']
      }
    ],
    'Parceiros': [
      {
        title: "Programa de Indicações",
        path: "/parceiros/painel",
        permissions: ['Partner'],
        verifyHostOps: true
      }
    ]
  }

  type UserMainRole = string;
  const universeMappings: Record<UserMainRole, UniversesNavItemComponentOptions> = {
    'Admin': universeMappingsBackoffice,
    'Seazone': universeMappingsBackoffice,
    'Attendant': universeMappingsBackoffice,
    'Host': universeMappingsHost,
  }

  const activitiesMappings: Record<UserMainRole, UniversesActivitiesOptions> = {
    'Admin': activitiesMappingsBackoffice,
    'Seazone': activitiesMappingsBackoffice,
    'Attendant': activitiesMappingsBackoffice,
    'Host': activitiesMappingsHost,
  }

  const universeIconsWhiteMappings: UniversesNavItemComponentOptions = {
    'Operacional': (
      <LogoUniverseOperational color="white" />
    ),
    'Financeiro': (
      <LogoUniverseFinances color="white" />
    ),
    'Proprietário': (
      <LogoUniverseOwner color="white" />
    ),
    'Anfitrião': (
      <LogoUniverseHost color="white" />
    ),
    'Parceiros': (
      <LogoUniversePartner color="white" />
    ),
  }

  const universeIconsBlueMappings: UniversesNavItemComponentOptions = {
    'Operacional': (
      <LogoUniverseOperational color="blue" />
    ),
    'Financeiro': (
      <LogoUniverseFinances color="blue" />
    ),
    'Proprietário': (
      <LogoUniverseOwner color="blue" />
    ),
    'Anfitrião': (
      <LogoUniverseHost color="blue" />
    ),
    'Parceiros': (
      <LogoUniversePartner color="blue" />
    ),
  }

  return {
    universeMappingsBackoffice,
    universeMappingsHost,
    universeMappings: universeMappings[`${userInformation?.main_role}`],
    universeIconsWhiteMappings,
    universeIconsBlueMappings,
    activitiesMappings: activitiesMappings[`${userInformation?.main_role}`],
    activitiesMappingsBackoffice,
    activitiesMappingsHost,
  }
}

export default useUniverseMappings;
