import { CategoryActiveProps, useMenuLateral } from '../../../context/MenuLateral';
import { useScreenResize } from '../../../hooks/useSceenResize/useScreenResize';
import DropIcon from '../icons/DropIcon';

import {
  NavItemDiv,
  NavItemLink,
  Title 
} from './styles';

interface INavItemComponent {
  title: string,
  path?: string,
  permissions: string[],
  verifyHostOps?: boolean,
  className?: string,
  disableDropIcon?: boolean,
  handleOnClick?: Function,
};

const NavItemComponent = ({
  title,
  path,
  permissions,
  verifyHostOps = false,
  className = '',
  disableDropIcon = false,
  handleOnClick,
}: INavItemComponent) => {
  const { isMobile } = useScreenResize(900);
  const { shouldBeVisibleTo, categoryActive, setCategoryActive, setOpenMenu } = useMenuLateral();

  const handleClickOnCategory = () => {
    if (handleOnClick) handleOnClick();

    setCategoryActive((state: CategoryActiveProps) => ({
      title,
      actived: !(state.title === title && state.actived),
    }));
  };

  if (!path) {
    return (
      <NavItemDiv
        onClick={() => handleClickOnCategory()}
        className={className}
        shouldBeVisible={shouldBeVisibleTo(permissions, verifyHostOps)}
      >
        <Title>{title}</Title>
        {!disableDropIcon && <DropIcon expanded={categoryActive.title === title && categoryActive.actived} />}
      </NavItemDiv>
    )
  }

  return (
    <NavItemLink
      to={path}
      className={className}
      shouldBeVisible={shouldBeVisibleTo(permissions, verifyHostOps)}
      onClick={isMobile ? () => setOpenMenu(false) : () => {}}
    >
      <Title>{title}</Title>
    </NavItemLink>
  );
}

NavItemComponent.defaultProps = {
  path: undefined,
  verifyHostOps: false,
  className: '',
  disableDropIcon: false,
  handleOnClick: undefined,
};

export default NavItemComponent;
