import { Container } from './styles';

interface ILogoUniverseFinances {
  color?: 'white' | 'blue',
};

const LogoUniverseFinances = ({ color = 'white' }: ILogoUniverseFinances) => (
  <Container>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M11.668 4.1665C11.668 2.78579 12.7873 1.6665 14.168 1.6665H15.8346C17.2153 1.6665 18.3346 2.78579 18.3346 4.1665V15.8332C18.3346 17.2139 17.2153 18.3332 15.8346 18.3332H14.168C13.5277 18.3332 12.9436 18.0925 12.5013 17.6966C12.059 18.0925 11.4749 18.3332 10.8346 18.3332H9.16797C8.52767 18.3332 7.9436 18.0925 7.5013 17.6966C7.05901 18.0925 6.47493 18.3332 5.83464 18.3332H4.16797C2.78726 18.3332 1.66797 17.2139 1.66797 15.8332V10.8332C1.66797 9.45246 2.78726 8.33317 4.16797 8.33317H5.83464C6.12683 8.33317 6.40732 8.3833 6.66797 8.47543V7.49984C6.66797 6.11913 7.78726 4.99984 9.16797 4.99984H10.8346C11.1268 4.99984 11.4073 5.04997 11.668 5.14209V4.1665ZM13.3346 15.8332C13.3346 16.2934 13.7077 16.6665 14.168 16.6665H15.8346C16.2949 16.6665 16.668 16.2934 16.668 15.8332V4.1665C16.668 3.70627 16.2949 3.33317 15.8346 3.33317H14.168C13.7077 3.33317 13.3346 3.70627 13.3346 4.1665V15.8332ZM11.668 7.49984C11.668 7.0396 11.2949 6.6665 10.8346 6.6665H9.16797C8.70773 6.6665 8.33464 7.0396 8.33464 7.49984V15.8332C8.33464 16.2934 8.70773 16.6665 9.16797 16.6665H10.8346C11.2949 16.6665 11.668 16.2934 11.668 15.8332V7.49984ZM6.66797 10.8332C6.66797 10.3729 6.29487 9.99984 5.83464 9.99984H4.16797C3.70773 9.99984 3.33464 10.3729 3.33464 10.8332V15.8332C3.33464 16.2934 3.70773 16.6665 4.16797 16.6665H5.83464C6.29487 16.6665 6.66797 16.2934 6.66797 15.8332V10.8332Z" fill={color === 'blue' ? '#5274D8' : color} />
    </svg>
  </Container>
);

LogoUniverseFinances.defaultProps = {
  color: 'white',
};

export default LogoUniverseFinances;
