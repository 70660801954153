import { useEffect } from 'react';

import { useMenuLateral } from '../../../context/MenuLateral';
import { useScreenResize } from '../../../hooks/useSceenResize/useScreenResize';
import useUniverseMappings from '../hooks/useUniverseMappings';

import RetractMenuButton from '../icons/RetractMenuButton';
import RetractMenuButtonMobile from '../icons/RetractMenuButtonMobile';

import Universes from '../Universes';
import Footer from '../Footer';

import {
  Container,
  ContentLeft,
  ContentRight,
  NavBar,
  TitleContainerNavBar,
  TitleNavBar,
  SubtitleContainer,
  SubtitleNavBar,
  LogoUniverse,
  ButtonRetractMenu,
  FooterContainer,
  Wrapper,
} from './styles';

const MenuLateral = () => {
  const {
    retractMenu,
    setRetractMenu,
    openMenu,
    setOpenMenu,
    shouldBeVisibleTo,
    universeActive,
  } = useMenuLateral();

  const { universeMappings, universeIconsWhiteMappings } = useUniverseMappings();
  const { isMobile } = useScreenResize();

  useEffect(() => {
    setRetractMenu(true);
  }, [window.location.pathname]);

  useEffect(() => {
    if (!isMobile) {
      setOpenMenu(true);
    }
  }, [isMobile]);

  const NavBarComponent = () => (
    <>
      <TitleContainerNavBar>
        {isMobile && <RetractMenuButtonMobile />}
        <TitleNavBar>{isMobile ? 'SAPRON' : universeActive}</TitleNavBar>
      </TitleContainerNavBar>

      {isMobile && (
        <SubtitleContainer onClick={() => setRetractMenu(true)}>
          <LogoUniverse>
            {universeIconsWhiteMappings[universeActive]}
          </LogoUniverse>
          <SubtitleNavBar>{universeActive}</SubtitleNavBar>
        </SubtitleContainer>
      )}

      <NavBar>
        {universeMappings[universeActive]}
      </NavBar>

      {isMobile && (
        <FooterContainer>
          <Footer />
        </FooterContainer>
      )}
    </>
  );

  const Left = () => {
    if (isMobile) {
      if (retractMenu) {
        return (
          <ContentLeft>
            <Universes />
          </ContentLeft>
        )
      }
      return null;
    }

    return (
      <ContentLeft>
        <Universes />
      </ContentLeft>
    )
  }

  const Right = () => (
    <ContentRight retractMenu={retractMenu}>
      <ButtonRetractMenu onClick={isMobile ? () => setOpenMenu(false) : () => setRetractMenu(true)}>
        <RetractMenuButton />
      </ButtonRetractMenu>
      <NavBarComponent />
    </ContentRight>
  );

  const WrapperMenu = () => {
    if (!isMobile && !retractMenu) {
      return <Wrapper onClick={() => setRetractMenu(true)} />
    }

    if (isMobile && openMenu) {
      return <Wrapper onClick={() => setOpenMenu(false)} />
    }
    return null;
  }

  if (!openMenu) return null;

  return (
    <>
      <Container
        retractMenu={retractMenu}
        shouldBeVisible={shouldBeVisibleTo([
          'Admin',
          'SeazoneOnboarding',
          'SeazoneAdministrative',
          'Attendant',
          'Host',
        ])}
      >
        <Left />
        <Right />
      </Container>
      <WrapperMenu />
    </>
  );
};

MenuLateral.defaultProps = {
  openMenu: false,
  setOpenMenu: () => { },
  setIsOpen: () => { },
};

export default MenuLateral;
