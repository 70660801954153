import { useEffect } from 'react';
import { AxiosError } from 'axios';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';

import { getInfosPipedrive } from '../../../../../../services/Onboarding/request';

import { ErrorMessage } from '../../../../../../utils/Messages';
import { useToast } from '../../../../../../context/ToastContext';
import { useOnboardingHandover } from '../../../../../../hooks/useOnboarding/useOnboardingHandover';

import TextField from '../../../../../TextField';
import DragDrawerModal from '../../../../../Modal/DragDrawerModal';
import FormGridRow from '../../../../../FormGridRow';
import FormButton from '../../../../../FormButton';
import Spinner from '../../../../../InsertData/InsertChangePropertyHostData/Spinner';

import { ButtonSyncPipedrive, TextFieldSyncPipedrive } from '../../../AddOwner/styles';
import { Wrapper, Container, Content, ContainerSpinner } from './styles';

const ModalAddProperty = () => {
  const {
    owner,
    openModalAddProperty,
    setOpenModalAddProperty,
    setInfosPipeDrive,
    setPageVisible,
    setResetFormik,
    dealID,
    setDealID,
  } = useOnboardingHandover();

  const toast = useToast();

  const validation = Yup.object().shape({
    dealID: Yup.string().min(5, 'Informe no mínimo 5 caracteres').required(),
  });

  const formik = useFormik({
    initialValues: {
      dealID,
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      try {
        const result = await getInfosPipedrive(values.dealID);
        setInfosPipeDrive(result);
        setOpenModalAddProperty({
          open: false,
          variant: '1'
        });
        setPageVisible('Page_Forms');
        toast.success('Dados sincronizados com sucesso!');
      } catch (e: unknown) {
        if (e instanceof AxiosError) {
          if (e?.response) {
            if (e?.response?.status === 404) {
              toast.error('DEAL ID não encontrado');
            } else {
              toast.error(e.response.data || ErrorMessage.default());
            }
          }
        } else {
          toast.error(ErrorMessage.default());
        }
      }
    },
  });

  const handleCloseModal = () => {
    setOpenModalAddProperty({
      open: false,
      variant: '1'
    });
  }

  const handleAddManually = () => {
    setResetFormik(true);
    setDealID(formik.values.dealID);
    handleCloseModal();
    setPageVisible('Page_Forms');
  };

  useEffect(() => {
    if (`${formik.values.dealID}` === '') {
      formik.setFieldTouched('dealID', true, false);
      formik.setFieldError('dealID', 'Campo obrigatório');
    }
  }, [formik.values.dealID]);

  return (
    <Wrapper>
      <DragDrawerModal
        variant="withCancelAndAppyButton"
        title={formik.isSubmitting ? 'Sincronizando...' : 'Adicionar Imóvel'}
        cancelButtonText={'Cancelar'}
        applyButtonText={'Adicionar manualmente'}
        open={openModalAddProperty.open}
        onClose={() => handleCloseModal()}
        handleClickApplyButton={() => handleAddManually()}
        handleClickCancelButton={() => handleCloseModal()}
        maxWidthForModalOnDesk={'693px'}
        maxHeightForModalOnDesk={formik.errors.dealID ? '355px' : '330px'}
        activeDragHandAnimation={false}
        disableApplyButton={`${formik.values.dealID}`.length < 5 || formik.isSubmitting}
      >
        <Container onSubmit={formik.handleSubmit}>
          <ContainerSpinner>
            <Spinner loading={formik.isSubmitting} />
          </ContainerSpinner>
          {!formik.isSubmitting && (
            <FormGridRow grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  formik={formik}
                  id="name"
                  labelClassName="labelClassName"
                  label="Nome"
                  placeholder="Digite aqui..."
                  value={`${owner?.first_name} ${owner?.last_name}`}
                  disableRequireSymbol
                  disableHelperText
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  formik={formik}
                  id="personID"
                  labelClassName="labelClassName"
                  label="Person ID"
                  placeholder="Digite aqui..."
                  value={`${owner?.pipedrive_person_id || ''}`}
                  disableRequireSymbol
                  disableHelperText
                  disabled
                />
              </Grid>
            </FormGridRow>
          )}
          {!formik.isSubmitting && (
            <Content error={!!formik.errors.dealID}>
              <TextFieldSyncPipedrive>
                <TextField
                  formik={formik}
                  id="dealID"
                  label="Código Pipedrive"
                  placeholder="DEAL ID:"
                  labelClassName="field-deal"
                  value={formik.values.dealID}
                  error={!!formik.errors.dealID}
                  type="number"
                />
              </TextFieldSyncPipedrive>
              <ButtonSyncPipedrive>
                <FormButton
                  dataCy="btn-pull-pipedrive"
                  type="submit"
                  customClassName="button-deal"
                  disable={`${formik.values.dealID}`.length < 5}
                >
                  Sincronizar
                </FormButton>
              </ButtonSyncPipedrive>
            </Content>
          )}
        </Container>
      </DragDrawerModal>
    </Wrapper>
  );
};

export default ModalAddProperty;
