import {
  FC,
  ReactElement,
  useState,
} from 'react';

import { createContext } from 'use-context-selector';
import { useUser } from '../../../context/UserContext';

export type ActivitiesProps = {
  universe: string;
  title: string,
  path: string,
  permissions?: string[],
  verifyHostOps?: boolean,
}

type ModalAddActivityProps = {
  activityId: number,
  open: boolean,
}
export interface FavoriteActivitiesProps {
  openModalAddActivity: ModalAddActivityProps,
  setOpenModalAddActivity: Function,
  favoriteActivities: ActivitiesProps[]
  handleAddActivity: Function,
  handleRemoveActivity: Function,
}

export const FavoriteActivitiesContext = createContext<FavoriteActivitiesProps>({
  openModalAddActivity: {
    activityId: -1,
    open: false,
  },
  setOpenModalAddActivity: () => {},
  favoriteActivities: [],
  handleAddActivity: () => {},
  handleRemoveActivity: () => {},
});

export const FavoriteActivitiesProvider:FC<{ children: ReactElement }> = ({ children }) => {
  const { userInformation } = useUser();
  const userId = `${userInformation?.user_id}`;

  const [openModalAddActivity, setOpenModalAddActivity] = useState<ModalAddActivityProps>({
    activityId: -1,
    open: false,
  });
  
  const handleGetActivities = () => {
    const activities = localStorage.getItem(`@saprom-pms-web/favorite-activities-user:${userId}`);
    return activities ? JSON.parse(activities) as Array<ActivitiesProps> : [
      {
        universe: '',
        title: 'Adicionar Atividade',
        path: '',
      },
      {
        universe: '',
        title: 'Adicionar Atividade',
        path: '',
      },
      {
        universe: '',
        title: 'Adicionar Atividade',
        path: '',
      },
      {
        universe: '',
        title: 'Adicionar Atividade',
        path: '',
      }
    ]; 
  }

  const [favoriteActivities, setFavoriteActivities] = useState<ActivitiesProps[]>(handleGetActivities());

  const handleAddActivity = (id: number, newActivity: ActivitiesProps) => {
    const currentActivities = handleGetActivities();
    const newActivities = [...currentActivities];
    newActivities[id] = {
      universe: newActivity.universe, 
      title: newActivity.title,
      path: newActivity.path,
    };

    localStorage.setItem(`@saprom-pms-web/favorite-activities-user:${userId}`, JSON.stringify(newActivities));
    setFavoriteActivities(newActivities);
  }
  
  const handleRemoveActivity = (id: number) => {
    const currentActivities = handleGetActivities();
    const newActivities = [...currentActivities];
    newActivities[id] = {
      universe: '',
      title: 'Adicionar Atividade',
      path: '',
    };

    localStorage.setItem(`@saprom-pms-web/favorite-activities-user:${userId}`, JSON.stringify(newActivities));
    setFavoriteActivities(newActivities);
  }

  return (
    <FavoriteActivitiesContext.Provider
      value={{
        openModalAddActivity,
        setOpenModalAddActivity,
        favoriteActivities,
        handleAddActivity,
        handleRemoveActivity,
      }}
    >
      {children}
    </FavoriteActivitiesContext.Provider>

  );
};
