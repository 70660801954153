import Papa from 'papaparse';

function validateValue(value: string): boolean {
  try {
    const valueRegex = /^\d+(\.\d{1,2})?$/;
    return valueRegex.test(value.trim());
  } catch {
    return false;
  }
}

function validateDate(date: string): boolean {
  try {
    const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
    if (!dateRegex.test(date.trim())) return false;

    const parsedDate = new Date(date);
    return parsedDate instanceof Date && !Number.isNaN(parsedDate.getTime());
  } catch {
    return false;
  }
}

function processErrors(
  row: any,
  lineNumber: number,
  column: string,
  validateFn: (value: string) => boolean,
  errorsArray: string[],
  errorLimit: number,
  errorMessage: string
) {
  if (!validateFn(row[column]) && errorsArray.length < errorLimit) {
    errorsArray.push(`Linha ${lineNumber}, coluna "${column}": ${errorMessage}`);
  }
}

export function validateCSV(file: File | undefined): Promise<void> {
  if (!file) {
    return Promise.reject(Error("Arquivo não fornecido"));
  }

  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      header: true,
      complete: (result) => {
        const errors: string[] = [];
        const errorLimit = 10;

        const validationRules = [
          {
            column: "Valor",
            validateFn: validateValue,
            errorsArray: [] as string[],
            errorMessage: "utilize apenas o separador decimal (ex: '1500.50').",
          },
          {
            column: "Data Referencia",
            validateFn: validateDate,
            errorsArray: [] as string[],
            errorMessage: "utilize o formato 'AAAA-MM-DD' (ex: 2024-12-26).",
          },
          {
            column: "Data Pagamento",
            validateFn: validateDate,
            errorsArray: [] as string[],
            errorMessage: "utilize o formato 'AAAA-MM-DD' (ex: 2024-12-26).",
          },
        ];

        result.data.forEach((row: any, rowIndex: number) => {
          const lineNumber = rowIndex + 2;

          validationRules.forEach((rule) =>
            processErrors(
              row,
              lineNumber,
              rule.column,
              rule.validateFn,
              rule.errorsArray,
              errorLimit,
              rule.errorMessage
            )
          );
        });

        validationRules.forEach((rule) => {
          if (rule.errorsArray.length === errorLimit) {
            errors.push(`Muitos erros na coluna ${rule.column}: ${rule.errorMessage}`);
          } else {
            errors.push(...rule.errorsArray);
          }
        });

        if (errors.length > 0) {
          errors.unshift("Formatação inválida:");
          reject(Error(errors.join('\n')));
        } else {
          resolve();
        }
      },
      error: (error) => {
        reject(Error(`Erro ao processar o arquivo CSV: ${error.message}`));
      },
    });
  });
}
