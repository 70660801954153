/* eslint-disable max-len */
/* eslint-disable no-param-reassign */
import React, {
  FC,
  useMemo,
  useEffect,
  useRef,
} from 'react';

import Lottie from 'lottie-react';

import useMeasure from 'react-use-measure';
import { useDragControls, useMotionValue, animate } from 'framer-motion';
import { useScreenResize } from '../../../hooks/useSceenResize/useScreenResize';

import animationSource from '../../../assets/animations/source/dragHand.json';

import { Color } from '../../FormButton/types';
import FormButton from '../../FormButton';
import CloseButton from '../components/CloseButton';

import {
  DragContainer,
  DragContent,
  DragButton,
  Content,
  Header,
  Body,
  Footer,
  Title,
  ButtonsContainer,
  DragHandContainer,
} from './styles';

interface IDragDrawerModal {
  variant?: 'withoutButton' | 'withAppyButton' | 'withCancelAndAppyButton';
  title: string;
  open: boolean;
  onClose: Function;
  children: React.ReactElement;
  handleClickApplyButton?: Function;
  handleClickCancelButton?: Function;
  applyButtonText?: string;
  cancelButtonText?: string;
  activeDragHandAnimation?: boolean,
  maxWidthForModalOnDesk?: string;
  maxHeightForModalOnDesk?: string;
  initialPositionDrag?: number,
  disableApplyButton?: boolean,
  applyButtonColor?: Color,
}

const DragDrawerModal: FC<IDragDrawerModal> = ({
  variant = 'withoutButton',
  title,
  open,
  onClose,
  children,
  handleClickApplyButton = () => {},
  handleClickCancelButton = () => {},
  applyButtonText = 'Aplicar',
  cancelButtonText = 'Cancelar',
  activeDragHandAnimation = true,
  maxWidthForModalOnDesk,
  maxHeightForModalOnDesk,
  disableApplyButton = false,
  applyButtonColor,
  initialPositionDrag,
}) => {
  const { isMobile } = useScreenResize(900);
  const initialDragPosition = useMemo(() => initialPositionDrag || (isMobile && activeDragHandAnimation ? window.innerHeight / 2 : 0), [isMobile]);
  const dragPosition = useMotionValue(initialDragPosition);
  const [drawerRef, { height }] = useMeasure();
  const controls = useDragControls();
  const isDragging = useRef<boolean>(false);

  const ANIMATION_DURATION = 0.5;

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isMobile) {
        event.preventDefault();
        event.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isMobile]);

  const handleClose = () => {
    const newPosition = open ? height : initialDragPosition;

    animate(
      dragPosition,
      newPosition,
      { type: 'tween', duration: ANIMATION_DURATION, ease: 'easeInOut' },
    );

    onClose();
  };

  useEffect(() => {
    const unsubscribe = dragPosition.onChange((value) => {
      if (isMobile && value >= window.innerHeight - 100) {
        handleClose();
      }
    });
    return unsubscribe;
  }, [dragPosition, isMobile]);

  const Button = () => {
    if (variant === 'withAppyButton') {
      return (
        <ButtonsContainer>
          <FormButton
            type="button"
            onClick={() => handleClickApplyButton()}
            disable={disableApplyButton}
            customColor={applyButtonColor}
          >
            {applyButtonText}
          </FormButton>
        </ButtonsContainer>
      );
    }

    if (variant === 'withCancelAndAppyButton') {
      return (
        <ButtonsContainer>
          <FormButton
            type="button"
            variant="outlined"
            customColor="blue-dark"
            onClick={() => handleClickCancelButton()}
          >
            {cancelButtonText}
          </FormButton>
          <FormButton
            type="button"
            onClick={() => handleClickApplyButton()}
            disable={disableApplyButton}
            customColor={applyButtonColor}
          >
            {applyButtonText}
          </FormButton>
        </ButtonsContainer>
      );
    }

    return null;
  };

  const DragHandAnimation = () => {
    const dragHandVariants = {
      open: {
        display: 'none',
        transition: {
          delay: 3,
          duration: ANIMATION_DURATION * 1.5,
          ease: 'easeIn',
        },
      },
    };

    const userAlreadyViewed = localStorage.getItem('@sapron-pms-web/dragHandAnimationViewed');

    if (!isMobile || !activeDragHandAnimation || userAlreadyViewed === 'true') return null;

    useEffect(() => {
      setTimeout(() => {
        localStorage.setItem('@sapron-pms-web/dragHandAnimationViewed', 'true');
      }, 5000);
    }, []);

    return (
      <DragHandContainer
        animate="open"
        variants={dragHandVariants}
        transition={{
          delay: 2,
          type: 'tween',
          ease: open ? 'easeIn' : 'easeOut',
        }}
      >
        <Lottie animationData={animationSource} />
      </DragHandContainer>
    );
  };

  const dragContainerVariants = {
    open: { display: 'flex' },
    closed: { display: 'none' },
  };

  const dragContentVariants = {
    open: { y: initialDragPosition, opacity: 1, transition: { duration: ANIMATION_DURATION, ease: 'easeIn' } },
    closed: { y: isMobile ? initialDragPosition + 100 : height, opacity: 0, transition: { duration: ANIMATION_DURATION, ease: 'easeOut' } },
  };

  return (
    <DragContainer
      animate={open ? 'open' : 'closed'}
      initial="closed"
      variants={dragContainerVariants}
      transition={{
        delay: ANIMATION_DURATION - 0.2,
        type: 'tween',
        ease: open ? 'easeIn' : 'easeOut',
      }}
    >
      <DragHandAnimation />

      <DragContent
        ref={drawerRef}
        maxWidthForModalOnDesk={maxWidthForModalOnDesk}
        maxHeightForModalOnDesk={maxHeightForModalOnDesk}
        onClick={(e) => e.stopPropagation()}
        style={{ y: dragPosition }}
        drag="y"
        dragControls={controls}
        dragListener={isMobile && activeDragHandAnimation}
        dragConstraints={{ top: 0, bottom: height }}
        dragElastic={{ top: 0, bottom: 0.5 }}
        onDragStart={() => {
          isDragging.current = true;
        }}
        onDragEnd={(_, info) => {
          if (isMobile && !info.offset.y && isDragging.current) {
            handleClose();
          }
          isDragging.current = false;
        }}
        initial="closed"
        animate={open ? 'open' : 'closed'}
        variants={dragContentVariants}
      >
        {activeDragHandAnimation && <DragButton type="button" onPointerDown={(e) => controls.start(e)} />}
        <Content
          id="content-modal"
          maxWidthForModalOnDesk={maxWidthForModalOnDesk}
          maxHeightForModalOnDesk={maxHeightForModalOnDesk}
        >
          <Header id="header-modal">
            <Title id="title-modal">{title}</Title>
            <CloseButton onClose={() => handleClose()} />
          </Header>
          <Body id="body-modal">
            {children}
          </Body>
        </Content>
        {!isMobile && variant !== 'withoutButton' && (
          <Footer id="footer-modal">
            <Button />
          </Footer>
        )}
      </DragContent>
      {isMobile && variant !== 'withoutButton' && (
        <Footer id="footer-modal">
          <Button />
        </Footer>
      )}
    </DragContainer>
  );
};

export default DragDrawerModal;
