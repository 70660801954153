import styled from "styled-components";
import { Link } from 'react-router-dom';

const MAX_SIZE_MOBILE = 900;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 72%;
  
  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    height: 76%;
  }
`;

export const Header = styled.div`
  display: none;
  
  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    width: 100%;
    border-bottom: 1px solid #E0E0E0;
    padding: 16px 48px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 166px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 0 32px;
  
  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    padding: 0px;
  }
`;

export const Welcome = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Title = styled.p`
  padding: 28.29px 0 26px 0;
  
  color: #132644;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px; 
  letter-spacing: 0.24px;
  
  strong {
    font-size: 24px;
    font-weight: 600;
  }

  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    font-size: 32px;
    padding: 30.3px 0 36px 0;
  
    strong {
      font-size: 32px;
    }
  }
`;

export const Text = styled.p`
  color: #132644;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
  text-align: center;
  
  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    font-size: 20px;
  }
`;

export const Activities = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  padding-top: 50.52px;  
  width: 100%;

  @media(min-width: 500px) and (max-width: ${MAX_SIZE_MOBILE - 1}px) {
    align-items: center;
  }

  @media(min-width: ${MAX_SIZE_MOBILE}px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 37px;
    padding-top: 36.5px;  
  }
`;

export const ActivityContent = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const ActivityLink = styled(Link)`
  color: #394760;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

export const Activity = styled.p`
  color: #394760;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;

  &.customStyle {
    cursor: pointer;
    p {
      color: #394760;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-decoration: underline;
    }
  }
`;
