import { useScreenResize } from '../../../../hooks/useSceenResize/useScreenResize';

import { Container } from './styles';

const RetractMenuButton = () => {
  const { isMobile } = useScreenResize();

  return (
    <Container>
      {isMobile ? (
        <svg width="27" height="27" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="26.6274" height="26.6274" rx="8" fill="#F1605D" />
          <path d="M10.4854 10.4853L16.1422 16.1421" stroke="white" strokeLinecap="round" />
          <path d="M16.1421 10.4853L10.4852 16.1421" stroke="white" strokeLinecap="round" />
        </svg>
      ) : (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="28" height="28" rx="8" fill="#F1605D" />
          <path d="M16.4727 10.4716C16.7331 10.2112 16.7331 9.78911 16.4727 9.52876C16.2124 9.26841 15.7902 9.26841 15.5299 9.52876L11.5299 13.5288C11.2695 13.7891 11.2695 14.2112 11.5299 14.4716L15.5299 18.4716C15.7902 18.7319 16.2124 18.7319 16.4727 18.4716C16.7331 18.2112 16.7331 17.7891 16.4727 17.5288L12.9441 14.0002L16.4727 10.4716Z" fill="white" />
        </svg>
      )}
    </Container>
  )
}

export default RetractMenuButton;
