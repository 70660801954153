import {
  createContext,
  useState,
  ReactNode,
  useContext,
  useMemo,
} from 'react';

import { useUser } from './UserContext';

export type UniversesOptions = "Operacional" | "Financeiro" | "Proprietário" | "Anfitrião" | "Parceiros";

export type CategoryActiveProps = {
  title: string,
  actived: boolean,
};

interface MenuLateralContextData {
  retractMenu: boolean;
  setRetractMenu: Function;
  openMenu: boolean,
  setOpenMenu: Function,
  shouldBeVisibleTo: Function,
  universeActive: UniversesOptions,
  setUniverseActive: Function,
  categoryActive: CategoryActiveProps,
  setCategoryActive: Function,
}

interface Props {
  children: ReactNode;
}

const MenuLateralContext = createContext<MenuLateralContextData>({} as
  MenuLateralContextData);

export const MenuLateralProvider = ({
  children,
}: Props) => {
  const { userInformation } = useUser();
  const [retractMenu, setRetractMenu] = useState<boolean>(true);
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const [universeActive, setUniverseActive] = useState<UniversesOptions>('Operacional');
  const [categoryActive, setCategoryActive] = useState<CategoryActiveProps>({ title: '', actived: false });

  const roles = useMemo(() => userInformation?.roles || [], [userInformation]);

  const handleShouldBeVisibleTo = (permissions: string[], verify_host_ops = false) => {
    const permission = permissions.find((role) => roles.includes(role));

    if (permission === 'Host' && verify_host_ops) {
      if (!userInformation?.host?.is_host_ops) {
        return true;
      }
      return false;
    }

    return !!permission;
  };

  const handleOpenMenu = (open: boolean) => {
    setOpenMenu(open);
    setRetractMenu(true);
    setUniverseActive('Operacional');
    setCategoryActive({
      title: '',
      actived: false,
    });
  };

  return (
    <MenuLateralContext.Provider value={{
      retractMenu,
      setRetractMenu,
      openMenu,
      setOpenMenu: handleOpenMenu,
      shouldBeVisibleTo: handleShouldBeVisibleTo,
      universeActive,
      setUniverseActive,
      categoryActive,
      setCategoryActive,
    }}
    >
      {children}
    </MenuLateralContext.Provider>
  );
};

export function useMenuLateral(): MenuLateralContextData {
  const {
    retractMenu,
    setRetractMenu,
    openMenu,
    setOpenMenu,
    shouldBeVisibleTo,
    universeActive,
    setUniverseActive,
    categoryActive,
    setCategoryActive,
  } = useContext(MenuLateralContext);

  return {
    retractMenu,
    setRetractMenu,
    openMenu,
    setOpenMenu,
    shouldBeVisibleTo,
    universeActive,
    setUniverseActive,
    categoryActive,
    setCategoryActive,
  };
}
