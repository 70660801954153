/* eslint-disable no-nested-ternary */
/* eslint-disable no-restricted-globals */
import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

import {
  CNPJ,
  CPF,
  Gender,
  Phone,
} from '../../../services/types';

import { AddressDetail } from '../../../services/Address/types';

import {
  deleteUser,
  getUserDetailsById,
  patchEditPartner,
  postUser,
} from '../../../services/InsertData/request';

import {
  postAddress,
  deleteAddress,
  patchAddress,
} from '../../../services/Address/request';

import {
  deletePartner,
  getPartnerById,
  patchUser,
  postPartner,
} from '../../../services/Partner/request';

import { useToast } from '../../../context/ToastContext';
import { useToastErrorMessage } from '../../../utils/Messages';

import FormAddUser from './Forms/FormAddUser';
import FormAddPartner from './Forms/FormAddPartner';
import FormButton from '../../FormButton';

import {
  Container,
  Title,
  Divider,
  Form,
  ButtonsContainer,
} from './styles';

import { onlyNumbers } from '../../../utils/Formatter';
import { Number as Mask } from '../../../utils/InputMask';
import { UserPatch } from '../../../services/Partner/types';

type FormState = 'user' | 'partner';
type MaritalStatus = 'Single' | 'Married' | 'Divorced' | 'Widowed' | undefined;

interface AddPartnerFormikValuesType {
  formType: FormState;
  firstName: string;
  lastName: string;
  nickname: string;
  email: string;
  password: string;
  passwordConfirmation: string;
  phoneNumber1: string;
  phoneNumber2: string;
  gender: string;
  birthDate: Date | undefined;
  cpf?: string;
  cnpj?: string;
  corporateName: string;
  tradingName: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: string;
  state: string;
  country: string;
  postalCode: string;
  nationality: string;
  maritalStatus: MaritalStatus;
  profession: string;
  emailForOperation: string;
  zipCode: string;
  partnerId?: number;
  isIndividual: boolean;
  signedContract: boolean;
  link: string,
  phoneNumberAttendent: string,
  attendent: string,
  executive: number,
  pipedrivePersonId?: string,
}

interface PartnerDetailsProps {
  mainAddress: number; // nu
  postalAddress: number; // nu
  firstName: string; // user
  lastName: string; // user
  email?: string; // user
  phoneNumber1?: string; // user
  phoneNumber2?: string; // user
  gender: string; // user
  birthDate?: string; // user
  cpf?: string // user
  cnpj?: string; // user
  corporateName?: string; // user
  tradingName?: string; // user
  nickname?: string; // user
  profession: string; // partner
  nationality?: string; // partner
  maritalStatus?: MaritalStatus; // partner
  emailForOperation?: string; // partner
  user: {
    id?: number,
    first_name: string,
    last_name: string,
    email: string,
    phone?: Phone,
    cpf?: CPF,
    cnpj?: CNPJ,
    trading_name: string,
    phone_number1: string,
    main_address?: AddressDetail;
    pipedrive_person_id?: string;
  };
  neighborhood: string; // address
  country: string; // address
  street: string; // address
  number?: string; // address
  complement?: string; // address
  city: string; // address
  state: string; // address
  postalCode: string; // address
  condominium?: string; // address
  isDefault?: boolean;
  transfer_day?: string;
  partnerId: number;
  userId: number;
  addressId: number;
  mainRole: string;
  isIndividual?: boolean;
  spreadhsheet_link: string;
  attendant_name: string;
  attendant_phone_number: string;
  executive: number;
  signed_contract: boolean;
  pipedrive_person_id?: string;
}

const InsertAddPartnerData = () => {
  const isEditingForm = [
    '/editardados/parceiro',
    'editardados/parceiro',
    '/editardados/parceiro/',
    'editardados/parceiro/',
  ].includes(window.location.pathname);

  const [passwordFirstNameRegexp, setPasswordFirstNameRegexp] = useState<RegExp>();
  const [passwordLastNameRegexp, setPasswordLastNameRegexp] = useState<RegExp>();
  const [formState, setFormState] = useState<FormState>('user');
  const [disable, setDisable] = useState(true);
  const [allPartnerDetails,
    setAllPartnerDetails] = useState<PartnerDetailsProps>({} as PartnerDetailsProps);

  const toast = useToast();
  const toastErrorRequest = useToastErrorMessage();
  const navigate = useNavigate();

  function padTo2Digits(number: number) {
    return number.toString().padStart(2, '0');
  }

  function formatDate(date?: Date | string) {
    if (date) {
      const current = new Date(date);
      if (current.toString() !== 'Invalid Date') {
        return [
          current.getFullYear(),
          padTo2Digits(current.getMonth() + 1),
          padTo2Digits(current.getDate()),
        ].join('-');
      }
    }
    return undefined;
  }

  function handleAge(date?: Date) {
    if (date) {
      const current = new Date(date);
      if (current.toString() !== 'Invalid Date') {
        return new Date().getFullYear() - date.getFullYear();
      }
    }
    return 0;
  }

  const initialValues = {
    formType: 'user' as FormState,
    firstName: !isEditingForm ? '' : (allPartnerDetails.firstName || ''), // User
    lastName: !isEditingForm ? '' : (allPartnerDetails.lastName || ''), // User
    nickname: !isEditingForm ? '' : (allPartnerDetails.nickname || ''), // User
    email: !isEditingForm ? '' : (allPartnerDetails.email || ''), // User
    password: '', // User
    passwordConfirmation: '', // User
    phoneNumber1: !isEditingForm ? '' : Mask.phone(allPartnerDetails.phoneNumber1 || ''), // User
    phoneNumber2: !isEditingForm ? '' : Mask.phone(allPartnerDetails.phoneNumber2 || ''), // User
    gender: !isEditingForm ? 'Not informed' as Gender : (allPartnerDetails.gender || 'Not informed' as Gender), // User
    birthDate: !isEditingForm ? undefined
      : moment(allPartnerDetails?.birthDate || formatDate(new Date())).toDate(), // User
    cpf: !isEditingForm ? '' : Mask.cpf(allPartnerDetails.cpf || ''), // User
    cnpj: !isEditingForm ? '' : Mask.cnpj(allPartnerDetails.cnpj || ''), // User
    corporateName: !isEditingForm ? '' : (allPartnerDetails.corporateName || ''), // User
    tradingName: !isEditingForm ? '' : (allPartnerDetails.tradingName || ''), // User
    street: !isEditingForm ? '' : (allPartnerDetails.street || ''), // Address
    number: !isEditingForm ? '' : (allPartnerDetails.number || ''), // Address
    complement: !isEditingForm ? '' : (allPartnerDetails.complement || ''), // Address
    neighborhood: !isEditingForm ? '' : (allPartnerDetails.neighborhood || ''), // Address
    city: !isEditingForm ? '' : (allPartnerDetails.city || ''), // Address
    state: !isEditingForm ? '' : (allPartnerDetails.state || ''), // Address
    country: !isEditingForm ? '' : (allPartnerDetails.country || ''), // Address
    postalCode: !isEditingForm ? '' : Mask.cep(allPartnerDetails.postalCode || ''), // Address
    nationality: !isEditingForm ? '' : (allPartnerDetails.nationality || ''), // partner
    maritalStatus: !isEditingForm ? undefined
      : (allPartnerDetails.maritalStatus || undefined), // partner
    profession: !isEditingForm ? '' : (allPartnerDetails.profession || 'Não informado'), // partner
    emailForOperation: !isEditingForm ? '' : (allPartnerDetails.emailForOperation || ''), // partner
    transferDay: !isEditingForm ? ''
      : (allPartnerDetails.transfer_day || 'Não informado'),
    zipCode: '',
    partnerId: !isEditingForm ? undefined : (allPartnerDetails.partnerId || undefined),
    isIndividual: !isEditingForm ? true : (allPartnerDetails?.isIndividual || true),
    signedContract: allPartnerDetails.signed_contract || false,
    link: allPartnerDetails?.spreadhsheet_link || '',
    phoneNumberAttendent: allPartnerDetails.attendant_phone_number || '',
    attendent: allPartnerDetails.attendant_name || '',
    executive: allPartnerDetails.executive || 0,
    pipedrivePersonId: allPartnerDetails.pipedrive_person_id || '',
  };

  const validationInsert = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    email: Yup.string().email('Digite um e-mail valido').required(),
    password: Yup.string().required().min(8, 'Mínimo 8 caracteres')
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'A senha precisa ter no mínimo uma letra maiúscula ou uma letra minúscula, '
      + 'um número e um caracter especial')
      .matches(passwordFirstNameRegexp || /./, 'Senha fraca')
      .matches(passwordLastNameRegexp || /./, 'Senha fraca'),
    passwordConfirmation: Yup.string().required().min(8, 'Mínimo 8 caracteres').oneOf([Yup.ref('password'), null], 'As senhas não coincidem'),
    phoneNumber1: Yup.string().required(),
    gender: Yup.string().required(),
    street: Yup.string().required(),
    number: Yup.string(),
    complement: Yup.string(),
    neighborhood: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    country: Yup.string().required(),
    postalCode: Yup.string().min(9, 'Insira um CEP válido').required(),
    phoneNumberAttendent: Yup.string().required(),
    attendent: Yup.string().required(),
    executive: Yup.number().required(),

    corporateName: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-corporate-name', 'Campo obrigatório', (name: string) => {
          if (isIndividual) return true;
          return !['', 'null', 'undefined'].includes(`${name}`);
        },
      )),
    tradingName: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-trading-name', 'Campo obrigatório', (name: string) => {
          if (isIndividual) return true;
          return !['', 'null', 'undefined'].includes(`${name}`);
        },
      )),
    cpf: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-cpf', 'Insira um CPF válido', (documentNumber: string) => {
          if (!isIndividual) return true;
          return (['', 'null', 'undefined'].includes(`${documentNumber}`) || (documentNumber && `${documentNumber}`.length === 14));
        },
      )),
    cnpj: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-cnpj', 'Insira um CNPJ válido', (documentNumber: string) => {
          if (isIndividual) return true;
          return (['', 'null', 'undefined'].includes(`${documentNumber}`) || (documentNumber && `${documentNumber}`.length === 18));
        },
      )),
  });

  const validationEdit = Yup.object().shape({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    gender: Yup.string().required(),
    street: Yup.string().required(),
    neighborhood: Yup.string().required(),
    city: Yup.string().required(),
    state: Yup.string().required(),
    country: Yup.string().required(),
    postalCode: Yup.string().min(9, 'Insira um CEP válido').required(),

    corporateName: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-corporate-name', 'Campo obrigatório', (name: string) => {
          if (isIndividual) return true;
          return !['', 'null', 'undefined'].includes(`${name}`);
        },
      )),
    tradingName: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-trading-name', 'Campo obrigatório', (name: string) => {
          if (isIndividual) return true;
          return !['', 'null', 'undefined'].includes(`${name}`);
        },
      )),
    cpf: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-cpf', 'Insira um CPF válido', (documentNumber: string) => {
          if (!isIndividual) return true;
          return (['', 'null', 'undefined'].includes(`${documentNumber}`) || (documentNumber && `${documentNumber}`.length === 14));
        },
      )),
    cnpj: Yup.string()
      .when('isIndividual', (isIndividual: boolean, schema) => schema.test(
        'test-cnpj', 'Insira um CNPJ válido', (documentNumber: string) => {
          if (isIndividual) return true;
          return (['', 'null', 'undefined'].includes(`${documentNumber}`) || (documentNumber && `${documentNumber}`.length === 18));
        },
      )),
  });

  // Função para transformar os valores de entrada
  function transformValues(values: AddPartnerFormikValuesType) {
    return {
      onlyNumbersCPF: onlyNumbers(`${values.cpf}`),
      onlyNumbersCNPJ: onlyNumbers(`${values.cnpj}`),
      onlyNumbersCEP: onlyNumbers(`${values.postalCode}`),
      onlyNumbersPhoneNumber1: Mask.phoneToNumber(`${values.phoneNumber1}`),
      onlyNumbersPhoneNumber2: Mask.phoneToNumber(`${values.phoneNumber2}`),
      onlyNumbersPhoneNumberAttendent: Mask.phoneToNumber(`${values.phoneNumberAttendent}`),
    };
  }

  // Função para criar o objeto de endereço
  function createAddressObject(values: AddPartnerFormikValuesType, onlyNumbersCEP: string) {
    return {
      city: values.city,
      street: values.street,
      complement: values.complement,
      country: values.country,
      neighborhood: values.neighborhood,
      number: values.number,
      postal_code: onlyNumbersCEP,
      state: values.state,
    };
  }

  // Função para criar o objeto de usuário
  function createUserObject(
    values: AddPartnerFormikValuesType,
    onlyNumbersObject: {
      onlyNumbersPhoneNumber1: string,
      onlyNumbersPhoneNumber2: string,
      onlyNumbersCPF: string,
      onlyNumbersCNPJ: string,
    },
    createdAddressId: number) {
    return {
      first_name: values.firstName,
      last_name: values.lastName,
      nickname: values.nickname,
      email: values.email === allPartnerDetails.email ? '' : values.email,
      password: values.password,
      password_confirmation: values.passwordConfirmation,
      phone_number1: values.phoneNumber1 !== '' ? onlyNumbersObject.onlyNumbersPhoneNumber1 : '',
      phone_number2: values.phoneNumber2 !== '' ? onlyNumbersObject.onlyNumbersPhoneNumber2 : '',
      gender: values.gender,
      birth_date: values?.birthDate === null ? '' : formatDate(values?.birthDate),
      cpf: values.isIndividual ? onlyNumbersObject.onlyNumbersCPF : '',
      cnpj: values.isIndividual ? onlyNumbersObject.onlyNumbersCNPJ : '',
      corporate_name: values.corporateName,
      trading_name: values.tradingName,
      main_address: createdAddressId,
      main_role: 'Partner',
      is_individual: values.isIndividual,
      pipedrive_person_id: values.pipedrivePersonId,
    };
  }

  function createUserObjectPatch(values: AddPartnerFormikValuesType,
    onlyNumbersObject: {
      onlyNumbersPhoneNumber1: string,
      onlyNumbersPhoneNumber2: string,
      onlyNumbersCPF: string,
      onlyNumbersCNPJ: string,
    },
    createdAddressId: number) {
    return {
      nickname: values.nickname,
      email: values.email === allPartnerDetails.email ? undefined : values.email,
      phone_number1: values.phoneNumber1 !== '' ? onlyNumbersObject.onlyNumbersPhoneNumber1 : '',
      phone_number2: values.phoneNumber2 !== '' ? onlyNumbersObject.onlyNumbersPhoneNumber2 : '',
      gender: values.gender,
      birth_date: formatDate(values?.birthDate) === formatDate(new Date())
        ? undefined
        : formatDate(values?.birthDate),
      cpf: values.isIndividual ? onlyNumbersObject.onlyNumbersCPF : '',
      cnpj: !values.isIndividual ? onlyNumbersObject.onlyNumbersCNPJ : '',
      corporate_name: values.corporateName,
      trading_name: values.tradingName,
      main_address: createdAddressId,
      is_individual: values.isIndividual,
      pipedrive_person_id: values.pipedrivePersonId,
    };
  }
  // Função para criar o objeto de parceiro
  function createPartnerObject(
    values: AddPartnerFormikValuesType,
    onlyNumbersPhoneNumberAttendent: string,
    createdUserID: number) {
    return {
      marital_status: values.maritalStatus,
      nationality: values.nationality,
      profession: values.profession === '' ? 'Não informado' : values.profession,
      signed_contract: values.signedContract,
      user: createdUserID,
      attendant_name: values.attendent,
      attendant_phone_number: onlyNumbersPhoneNumberAttendent,
      executive: values.executive,
      spreadhsheet_link: values.link === '' ? 'Não informado' : values.link,
    };
  }

  // Função auxiliar para inserir os dados
  async function handleDataSubmission(values: AddPartnerFormikValuesType) {
    const onlyNumbersObject = transformValues(values);
    let createdAddressId = -1;
    let createdUserID = -1;
    let createdPartnerId = -1;

    try {
      const address = createAddressObject(values, onlyNumbersObject.onlyNumbersCEP);
      const addressResponse = await postAddress(address);
      createdAddressId = addressResponse?.id || -1;

      try {
        const user = createUserObject(values, onlyNumbersObject, createdAddressId);
        createdUserID = await postUser(user);

        try {
          const partner = createPartnerObject(
            values, onlyNumbersObject.onlyNumbersPhoneNumberAttendent, createdUserID);
          createdPartnerId = await postPartner(partner);
          navigate('/home');
          toast.success('Parceiro inserido com sucesso!');
        } catch (e) {
          if (createdPartnerId !== -1) await deletePartner(createdPartnerId);
          if (createdUserID !== -1) await deleteUser(createdUserID);
          if (createdAddressId !== -1) await deleteAddress(createdAddressId);
          if (e instanceof Error) toastErrorRequest(e);
        }
      } catch (e) {
        if (createdUserID !== -1) await deleteUser(createdUserID);
        if (createdAddressId !== -1) await deleteAddress(createdAddressId);
        if (e instanceof Error) toastErrorRequest(e);
      }
    } catch (e) {
      if (createdAddressId !== -1) await deleteAddress(createdAddressId);
      if (e instanceof Error) toastErrorRequest(e);
    }
  }

  // Função para atualizar (PATCH) os dados do parceiro
  async function handlePatchPartner(values: AddPartnerFormikValuesType) {
    const onlyNumbersObject = transformValues(values);

    try {
      const address = createAddressObject(values, onlyNumbersObject.onlyNumbersCEP);
      await patchAddress(allPartnerDetails.addressId, address);

      try {
        const user = createUserObjectPatch(values, onlyNumbersObject, allPartnerDetails.addressId);
        await patchUser(allPartnerDetails.userId, user);

        try {
          const partner = createPartnerObject(
            values, onlyNumbersObject.onlyNumbersPhoneNumberAttendent, allPartnerDetails.userId);
          await patchEditPartner(allPartnerDetails.partnerId, partner);
          navigate('/home');
          toast.success('Parceiro editado com sucesso!');
        } catch (e) {
          if (e instanceof Error) toastErrorRequest(e);
        }
      } catch (e) {
        if (e instanceof Error) toastErrorRequest(e);
      }
    } catch (e) {
      if (e instanceof Error) toastErrorRequest(e);
    }
  }

  // Função principal para handle submit
  async function handleInsertSubmit(values: AddPartnerFormikValuesType) {
    await handleDataSubmission(values);
  }

  // Função principal para handle patch
  async function handlePatchSubmit(values: AddPartnerFormikValuesType) {
    await handlePatchPartner(values);
  }

  const formik = useFormik<AddPartnerFormikValuesType>({
    initialValues,
    validationSchema: isEditingForm ? validationEdit : validationInsert,
    onSubmit: async (values) => {
      if (isEditingForm) {
        await handlePatchSubmit(values);
      } else {
        await handleInsertSubmit(values);
      }
    },
  });

  function handleRegexFirstName() {
    const regexpAux = `^((?!${formik.values.firstName}).)*$`;
    const regexp = new RegExp(regexpAux, 'i');
    setPasswordFirstNameRegexp(regexp);
  }

  function handleRegexLastName() {
    const regexpAux = `^((?!${formik.values.lastName}).)*$`;
    const regexp = new RegExp(regexpAux, 'i');
    setPasswordLastNameRegexp(regexp);
  }

  async function getPartnerDetails(idPartner: number) {
    let partnerDetails: PartnerDetailsProps = {} as PartnerDetailsProps;
    const partner = await getPartnerById(idPartner);
    const user = await getUserDetailsById(partner?.user?.id!);

    partnerDetails = {
      profession: partner.profession,
      emailForOperation: partner.user.email,
      maritalStatus: partner.marital_status as MaritalStatus,
      nationality: partner.nationality,
      spreadhsheet_link: partner.spreadhsheet_link,
      firstName: user.first_name,
      birthDate: user.birth_date,
      city: user.main_address.city,
      complement: user.main_address.complement,
      cnpj: Mask.cnpj(user.cnpj),
      cpf: Mask.cpf(user.cpf),
      corporateName: user.corporate_name,
      country: user.main_address.country,
      email: user.email,
      gender: user.gender,
      lastName: user.last_name,
      neighborhood: user.main_address.neighborhood,
      nickname: user.nickname,
      number: user.main_address.number,
      phoneNumber1: Mask.phone(user.phone_number1),
      phoneNumber2: Mask.phone(user.phone_number2),
      state: user.main_address.state,
      street: user.main_address.street,
      tradingName: user.trading_name,
      partnerId: partner.id,
      userId: user.id,
      addressId: user.main_address.id,
      mainAddress: user.main_address.id,
      postalAddress: user?.postal_address?.id || user.main_address.id,
      user: { ...partner.user },
      condominium: user.main_address.condominium,
      mainRole: user.main_role,
      isIndividual: user.is_individual,
      postalCode: user.main_address.postal_code,
      executive: partner.executive,
      attendant_name: partner.attendant_name,
      attendant_phone_number: partner.attendant_phone_number,
      signed_contract: partner.signed_contract,
      pipedrive_person_id: user.pipedrive_person_id,
    };
    setAllPartnerDetails(partnerDetails);
  }

  const handleClickNextForm = () => {
    setFormState('partner');
    formik.setFieldValue('formType', 'partner');
  };

  const handleBackForm = () => {
    setFormState('user');
    formik.setFieldValue('formType', 'user');
  };

  const checkAllFieldsIsFilled = () => {
    if (isEditingForm) {
      if (formik.values.firstName === '') {
        return false;
      }
      if (formik.values.lastName === '') {
        return false;
      }
      if (formik.values.gender === '') {
        return false;
      }
      if (formik.values.street === '') {
        return false;
      }
      if (formik.values.neighborhood === '') {
        return false;
      }
      if (formik.values.city === '') {
        return false;
      }
      if (formik.values.state === '') {
        return false;
      }
      if (formik.values.country === '') {
        return false;
      }
      if (formik.values.postalCode === '') {
        return false;
      }
      return true;
    }
    if (formik.values.firstName === '') {
      return false;
    }
    if (formik.values.lastName === '') {
      return false;
    }
    if (formik.values.email === '') {
      return false;
    }
    if (formik.values.password === '') {
      return false;
    }
    if (formik.values.passwordConfirmation === '') {
      return false;
    }
    if (formik.values.phoneNumber1 === '') {
      return false;
    }
    if (formik.values.gender === '') {
      return false;
    }
    if (formik.values.cpf === null && formik.values.cnpj === null) {
      return false;
    }
    if (formik.values.cpf === '' && formik.values.cnpj === '') {
      return false;
    }
    if (formik.values.cpf === '' && formik.values.cnpj === null) {
      return false;
    }
    if (formik.values.cpf === null && formik.values.cnpj === '') {
      return false;
    }
    if (formik.values.isIndividual === true && formik.values.cpf === '') {
      return false;
    }
    if (formik.values.isIndividual === false && (formik.values.cnpj === '' || formik.values.tradingName === '' || formik.values.corporateName === '')) {
      return false;
    }
    if (formik.values.street === '') {
      return false;
    }
    if (formik.values.neighborhood === '') {
      return false;
    }
    if (formik.values.city === '') {
      return false;
    }
    if (formik.values.state === '') {
      return false;
    }
    if (formik.values.country === '') {
      return false;
    }
    if (formik.values.postalCode === '') {
      return false;
    }
    return true;
  };

  const handleChangePersonType = (manualType?: boolean) => {
    if (manualType) {
      formik.setFieldValue('isIndividual', manualType);
    } else {
      formik.setFieldValue('isIndividual', !formik.values.isIndividual);
    }
  };

  const checkSubmit = async () => {
    try {
      const validation = isEditingForm ? validationEdit : validationInsert;
      await validation.validate(formik.values, { abortEarly: false });
    } catch (errors) {
      formik.setSubmitting(false);

      Object.values(formik.errors).forEach((item) => {
        toast.error(`${item}`);
      });
    }
  };

  useEffect(() => {
    if (formik.isSubmitting) {
      checkSubmit();
    }
  }, [formik.isSubmitting]);

  useEffect(() => {
    setFormState(formik.values.formType);
  }, [formik.values.formType]);

  useEffect(() => {
    if (isEditingForm) {
      if (checkAllFieldsIsFilled() && formik.values.partnerId !== undefined) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    } else if (checkAllFieldsIsFilled()) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [formik.values]);

  useEffect(() => {
    if (formik.values.partnerId !== undefined) {
      getPartnerDetails(formik.values.partnerId);
    } else {
      formik.resetForm();
    }
  }, [formik.values.partnerId]);

  useEffect(() => {
    if (isEditingForm && Object.keys(allPartnerDetails).length !== 0) {
      formik.setValues({
        ...initialValues,
        isIndividual: allPartnerDetails?.isIndividual !== undefined
          ? allPartnerDetails?.isIndividual : true,
      });
    }
  }, [isEditingForm, allPartnerDetails]);

  useEffect(() => {
    if (formik.values.firstName.length > 0) {
      handleRegexFirstName();
    }
  }, [formik.values.firstName]);

  useEffect(() => {
    if (formik.values.lastName.length > 0) {
      handleRegexLastName();
    }
  }, [formik.values.lastName]);

  useEffect(() => {
    if (formik?.values?.birthDate
      && formatDate(formik?.values?.birthDate) !== allPartnerDetails.birthDate) {
      const age = handleAge(formik?.values?.birthDate);
      if ((age < 12 || age > 123) && age !== 0) {
        formik.setFieldError('birthDate', 'A idade não é válida!');
      }
    }
  }, [formik.values.birthDate]);

  return (
    <Container>
      <div className="header">
        <div>
          <Title>{isEditingForm ? 'Editar Parceiro' : 'Novo Parceiro'}</Title>
        </div>
      </div>
      <div className="divider">
        <Divider />
      </div>
      <Form onSubmit={formik.handleSubmit}>
        <div className="formContainer">
          {formState === 'user' && (
            <FormAddUser
              formik={formik}
              isEditingForm={isEditingForm}
              handleChangePersonType={handleChangePersonType}
            />
          )}
          {formState === 'partner' && (
          <FormAddPartner
            formik={formik}
          />
          )}
        </div>
        <ButtonsContainer>
          <div>
            {formState === 'user' && (
              <>
                <FormButton type="button" variant="outlined" onClick={() => { navigate('/home'); }}>Cancelar</FormButton>
                <FormButton
                  type="button"
                  onClick={() => handleClickNextForm()}
                  disable={disable}
                >
                  Próximo
                </FormButton>
              </>
            )}
            {formState === 'partner' && (
              <>
                <FormButton type="button" variant="outlined" onClick={() => { handleBackForm(); }}>Voltar</FormButton>
                <FormButton type="submit" disable={formik.isSubmitting}>Salvar</FormButton>
              </>
            )}
          </div>
        </ButtonsContainer>
      </Form>
    </Container>
  );
};

export default InsertAddPartnerData;
