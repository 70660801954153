/* eslint-disable max-len */

import React, { useState, useMemo, useEffect } from 'react';
import { createContext, useContextSelector } from 'use-context-selector';

import { v4 as uuid } from 'uuid';

import { Eye } from 'react-feather';

import { useToast } from '../ToastContext';

import {
  Sort,
  ViewOwners,
  SavedAllOwners,
  IIndicateContextProps,
} from './types';

import { getAccountOwnerPerspective } from '../../services/AccountOwnerPerspective/AccountOwnerPerspective';
import { IAccountOwnerPerspective } from '../../services/AccountOwnerPerspective/types';

export const OwnerPerspectiveDashboardContext = createContext<IIndicateContextProps>({} as IIndicateContextProps);

type DataMapper = (data: IAccountOwnerPerspective[]) => SavedAllOwners;
const dataMapper: DataMapper = (data) => data.map((item) => ({
  id: uuid(),
  actions: <Eye />,
  name: item?.name,
  email: item?.email,
  userID: item?.user_id,
  ownerID: item?.owner_id,
  personID: item?.person_id,
}));

const ITEMS_PER_SCROLL = 50;
const ITEMS_PER_FILTER = 100;

export const OwnerPerspectiveDashboardProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const toast = useToast();

  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const [allOwners, setAllOwners] = useState<SavedAllOwners>([] as SavedAllOwners);
  const [viewOwners, setViewOwners] = useState<ViewOwners>({} as ViewOwners);

  const [sort, setSort] = useState<Sort>({
    name: null,
    direction: null,
  });

  const addMoreOwners = () => {
    setViewOwners((prevState) => ({
      ...prevState,
      next: prevState.owners.length < allOwners.length,
      owners: [
        ...prevState.owners,
        ...allOwners.slice(prevState.owners.length,
          prevState.owners.length + 25),
      ],
    }));
  };

  useEffect(() => {
    if (sort.name && sort.direction) {
      const sortedOwners = [...allOwners].sort((a: any, b: any) => {
        if (sort.name && sort.direction) {
          if (a[sort.name] < b[sort.name]) {
            return sort.direction === 'asc' ? -1 : 1;
          }
          if (a[sort.name] > b[sort.name]) {
            return sort.direction === 'asc' ? 1 : -1;
          }
        }
        return 0;
      });

      setViewOwners((prev) => ({
        ...prev,
        owners: sortedOwners.splice(0, ITEMS_PER_FILTER),
      }));
    }
  }, [sort]);

  useEffect(() => {
    const handleGetOwners = async (search?: string) => {
      try {
        setLoading(true);
        const response = await getAccountOwnerPerspective(search);

        setAllOwners(dataMapper(response));

        setViewOwners({
          next: dataMapper(response).length > ITEMS_PER_SCROLL,
          owners: dataMapper(response).splice(0, ITEMS_PER_SCROLL),
        });
      } catch (e) {
        if (e instanceof Error) {
          toast.error('Houve um problema ao sincronizar os dados de proprietários');
          toast.error(e.message);
        }
      } finally {
        setLoading(false);
      }
    };

    function mainQuery() {
      if (searchTerm !== '') {
        handleGetOwners(searchTerm);
      } else {
        handleGetOwners();
      }
    }

    mainQuery();
  }, [searchTerm]);

  const value = useMemo(() => ({
    sort,
    setSort,
    loading,
    allOwners,
    viewOwners,
    setAllOwners,
    addMoreOwners,
    setSearchTerm,
  }),
  [
    sort,
    setSort,
    loading,
    allOwners,
    searchTerm,
    viewOwners,
  ]);

  return (
    <OwnerPerspectiveDashboardContext.Provider value={value}>
      {children}
    </OwnerPerspectiveDashboardContext.Provider>
  );
};

export const useOwnerPerspectiveDashboard = () => {
  const allOwners = useContextSelector(OwnerPerspectiveDashboardContext,
    (state) => state.allOwners);

  const loading = useContextSelector(OwnerPerspectiveDashboardContext,
    (state) => state.loading);

  const setAllOwners = useContextSelector(OwnerPerspectiveDashboardContext,
    (state) => state.setAllOwners);

  const viewOwners = useContextSelector(OwnerPerspectiveDashboardContext,
    (state) => state.viewOwners);

  const addMoreOwners = useContextSelector(OwnerPerspectiveDashboardContext,
    (state) => state.addMoreOwners);

  const setSearchTerm = useContextSelector(OwnerPerspectiveDashboardContext,
    (state) => state.setSearchTerm);

  const sort = useContextSelector(OwnerPerspectiveDashboardContext,
    (state) => state.sort);

  const setSort = useContextSelector(OwnerPerspectiveDashboardContext,
    (state) => state.setSort);

  return {
    sort,
    setSort,
    loading,
    allOwners,
    viewOwners,
    setAllOwners,
    addMoreOwners,
    setSearchTerm,
  };
};
