import { Container } from './styles';

interface IDropIcon {
  expanded: boolean,
}

const DropIcon = ({ expanded }: IDropIcon) => {
  if (expanded) {
    return (
      <Container>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M12 10L8 6L4 10" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
      </Container>
    )
  };

  return (
    <Container>
      <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L5 5L9 1" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
      </svg>
    </Container>
  )
};

export default DropIcon;
