import { useNavigate } from 'react-router-dom';

import {
  Container,
  LogoContainer,
  MenuIconContainer,
  Margin,
} from './styles';

import MenuIcon from '../icons/MenuIcon';

import { useMenuLateral } from '../../../context/MenuLateral';

const HeaderMobile = () => {
  const { setOpenMenu } = useMenuLateral();
  const navigate = useNavigate();

  return (
    <>
      <Container>
        <LogoContainer onClick={() => navigate('/home')}>
          <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.7937 5.80387L12.8772 4.08721C12.8835 4.04649 12.9023 4.00889 12.9023 3.96817V1.37909C12.9023 0.949921 12.5542 0.602203 12.1245 0.602203H10.5691C10.1395 0.602203 9.79136 0.949921 9.79136 1.37909V2.28284L8.73296 1.66415C8.30333 1.40728 7.73728 1.45114 7.33901 1.7456L7.31863 1.75343L0.609158 5.64097C0.0493816 5.97459 -0.18425 6.74991 0.163846 7.33101C0.524486 7.93403 1.31946 8.09692 1.87767 7.7633L2.27908 7.53149C1.80397 8.4603 1.50762 9.50972 1.50762 10.6547C1.50762 12.4653 2.20381 14.2493 3.48957 15.5353C3.99447 16.0412 4.58247 16.4641 5.22692 16.7758C5.7224 17.0154 6.24612 17.2159 6.79021 17.3161C7.51149 17.4477 8.25786 17.3804 8.98698 17.3819C9.77411 17.3819 10.5628 17.3835 11.35 17.3851C12.0634 17.3866 12.7768 17.3898 13.4981 17.3913C13.6784 17.3913 13.8556 17.3443 14.0156 17.2629C14.221 17.1579 14.3433 17.0436 14.4781 16.8635C14.6318 16.6599 14.7384 16.4139 14.7384 16.1508V7.99981C15.2731 8.19247 15.9222 8.02487 16.2405 7.49233C16.5886 6.91124 16.355 6.13592 15.7937 5.8023M12.4381 10.7831C12.4381 13.261 10.7588 15.2753 8.2265 15.2753C5.69418 15.2753 3.83296 13.1326 3.83296 10.6547C3.83296 8.1768 5.69418 6.11243 8.2265 6.11243C10.7588 6.11243 12.4381 8.02331 12.4381 10.4746V10.7847V10.7831Z" fill="#FC6058" />
          </svg>
        </LogoContainer>

        <MenuIconContainer onClick={() => setOpenMenu((state: boolean) => !state)}>
          <MenuIcon />
        </MenuIconContainer>
      </Container>
      <Margin />
    </>
  );
};

export default HeaderMobile;
