import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Grid } from '@mui/material';

import { useToast } from '../../../../../context/ToastContext';
import { useUser } from '../../../../../context/UserContext';
import { ActivitiesProps } from '../../../context/FavoriteActivitiesContext';
import useActivity from '../../../hooks/useActivity';
import { UniversesOptions } from '../../../../../context/MenuLateral';
import useUniverseMappings from '../../../../../components/Sidebar/hooks/useUniverseMappings';

import { SimpleSelect } from '../../../../../components';
import { SelectOption } from '../../../../../components/SimpleSelect/SimpleSelect';
import DragDrawerModal from '../../../../../components/Modal/DragDrawerModal';
import FormGridRow from '../../../../../components/FormGridRow';

import { Wrapper, Container, Content } from './styles';

const ModalAddActivity = () => {
  const toast = useToast();
  const { activitiesMappings } = useUniverseMappings();
  const { userInformation } = useUser();

  const {
    handleAddActivity,
    openModalAddActivity,
    setOpenModalAddActivity
  } = useActivity();

  const handleCloseModal = () => {
    setOpenModalAddActivity({
      activityId: -1,
      open: false,
    });
  }

  const validation = Yup.object().shape({
    universeName: Yup.string().required(),
    featureName: Yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      universeName: '',
      featureName: '',
    },
    validationSchema: validation,
    onSubmit: async (values) => {
      const [title, path] = `${values.featureName}`.split(':');

      handleAddActivity(openModalAddActivity.activityId, {
        universe: values.universeName,
        title,
        path,
      });

      setOpenModalAddActivity({
        activityId: -1,
        open: false,
      });

      toast.success('Atividade adicionada com sucesso!');
    },
  });

  const getFeaturesByUser = (features: Array<Omit<ActivitiesProps, 'universe'>>) => {
    const roles = userInformation?.roles || [];

    return features.filter((item) => {
      const permissions = item?.permissions || [];
      const verifyHostOps = item?.verifyHostOps || false;

      if (verifyHostOps) {
        if (!userInformation?.host?.is_host_ops) return item;
      }

      return permissions.some(permission => roles.includes(permission));
    });
  };

  const getFeaturesByUniverse = (universe: UniversesOptions) => {
    if (['', 'undefined'].includes(`${universe}`)) return [];

    const features = getFeaturesByUser(activitiesMappings[universe]);

    const featuresFormatted: SelectOption[] = features.map((item) => ({
      valueLabel: item.title,
      value: `${item.title}:${item.path}`,
    }));

    return featuresFormatted;
  };


  const getUniverses = () => {
    const roles = userInformation?.roles || [];

    const universes = [
      { value: 'Operacional', valueLabel: 'Operacional' },
      { value: 'Financeiro', valueLabel: 'Financeiro' },
    ];

    if (roles.includes('Host')) {
      universes.push(
        { value: 'Anfitrião', valueLabel: 'Anfitrião' },
        ...(roles.includes('Partner') ? [{ value: 'Parceiros', valueLabel: 'Parceiros' }] : [])
      );
    } else {
      universes.push(
        ...(roles.includes('Admin') || roles.includes('SeazoneAdministrative') || roles.includes('SeazoneOnboarding')
          ? [{ value: 'Proprietário', valueLabel: 'Proprietário' }]
          : []),
        { value: 'Anfitrião', valueLabel: 'Anfitrião' },
        ...(roles.includes('Admin') || roles.includes('SeazoneOnboarding') || roles.includes('Partner')
          ? [{ value: 'Parceiros', valueLabel: 'Parceiros' }]
          : [])
      );
    }

    return universes;
  };

  return (
    <Wrapper>
      <DragDrawerModal
        variant="withCancelAndAppyButton"
        title={'Adicionar Atividade'}
        cancelButtonText={'Cancelar'}
        applyButtonText={'Salvar'}
        open={openModalAddActivity.open}
        onClose={() => handleCloseModal()}
        handleClickApplyButton={() => formik.handleSubmit()}
        handleClickCancelButton={() => handleCloseModal()}
        maxWidthForModalOnDesk={'693px'}
        maxHeightForModalOnDesk={'265.5px'}
        activeDragHandAnimation
        disableApplyButton={formik.isSubmitting}
        initialPositionDrag={55}
      >
        <Container onSubmit={formik.handleSubmit}>
          <Content>
            <FormGridRow grid>
              <Grid item xs={12} sm={6}>
                <SimpleSelect
                  formik={formik}
                  id="universeName"
                  placeholder="Selecione"
                  label="Universo"
                  labelClassName="labelClassName"
                  disableRequireSymbol
                  disableHelperText
                  options={getUniverses()}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <SimpleSelect
                  formik={formik}
                  id="featureName"
                  placeholder="Selecione"
                  label="Funcionalidade"
                  labelClassName="labelClassName"
                  disableRequireSymbol
                  disableHelperText
                  options={getFeaturesByUniverse(formik.values.universeName as UniversesOptions)}
                />
              </Grid>
            </FormGridRow>
          </Content>
        </Container>
      </DragDrawerModal>
    </Wrapper>
  );
};

export default ModalAddActivity;
