import styled from 'styled-components';

const MAX_SIZE_MOBILE = 900;

export const NavBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  align-self: stretch;
  
  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    gap: 16px;
  }

  @media(min-width: 1092px) and (max-width: 1093px) {
    gap: 4px;
  }

  @media(min-width: 1241px) and (max-width: 1245px) {
    gap: 16px;
  }

  @media(min-width: 1366px) and (max-width: 1370px) {
    gap: 20px;
  }
`;

export const NavItem = styled.div<{ shouldBeVisible: boolean }>`
  display: ${({ shouldBeVisible }) => (shouldBeVisible ? 'flex' : 'none')};
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  border-radius: 4px;

  p {
    color: #FFF;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.32px;
  }

  &:hover {
    background: #2D3251;
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    padding: 2px 0;
  }
`;

export const Title = styled.p`
  color: #FAFAFA;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.32px;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    width: calc(100% - 8px);
    padding: 16px 8px 8px 16px;
    border-bottom: 2px solid #FFF;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  width: 81px;
  height: auto;
  max-height: 40px;
  padding: 2px 0px;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    width: 310px;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    padding: 0 0 0 16px;
    max-height: 20px;
  }
`;
