import { Container } from './styles';

const MenuIcon = () => (
    <Container>
      <svg width="64" height="24" viewBox="0 0 64 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="63" height="23" rx="11.5" fill="white" />
        <rect x="0.5" y="0.5" width="63" height="23" rx="11.5" stroke="#DDDDDD" />
        <path d="M14 12H26M14 8H26M14 16H26" stroke="#222222" strokeWidth="1.5" strokeLinecap="square" strokeLinejoin="round" />
        <path d="M50.9707 12.5675C51.7589 11.9507 52.3342 11.105 52.6166 10.148C52.899 9.19097 52.8744 8.1702 52.5463 7.22771C52.2182 6.28521 51.6028 5.46786 50.7859 4.88937C49.9689 4.31087 48.991 4 47.9881 4C46.9852 4 46.0072 4.31087 45.1903 4.88937C44.3733 5.46786 43.758 6.28521 43.4298 7.22771C43.1017 8.1702 43.0772 9.19097 43.3595 10.148C43.6419 11.105 44.2172 11.9507 45.0054 12.5675C43.6548 13.1056 42.4764 13.9981 41.5958 15.1499C40.7151 16.3017 40.1653 17.6696 40.0048 19.1077C39.9932 19.2127 40.0025 19.3189 40.0322 19.4204C40.0619 19.5218 40.1113 19.6164 40.1778 19.6988C40.312 19.8652 40.5071 19.9718 40.7204 19.9952C40.9336 20.0185 41.1474 19.9566 41.3147 19.8232C41.4821 19.6897 41.5893 19.4956 41.6128 19.2836C41.7893 17.7206 42.5386 16.2772 43.7177 15.229C44.8967 14.1807 46.4227 13.6013 48.0042 13.6013C49.5856 13.6013 51.1116 14.1807 52.2906 15.229C53.4697 16.2772 54.219 17.7206 54.3956 19.2836C54.4174 19.48 54.5117 19.6615 54.6601 19.7929C54.8086 19.9243 55.0008 19.9964 55.1995 19.9952H55.2879C55.4987 19.9711 55.6913 19.8651 55.8238 19.7003C55.9563 19.5356 56.018 19.3254 55.9954 19.1157C55.8342 17.6735 55.2814 16.3021 54.3962 15.1485C53.5111 13.995 52.3269 13.1027 50.9707 12.5675ZM47.9881 11.9998C47.3521 11.9998 46.7303 11.8122 46.2015 11.4608C45.6726 11.1094 45.2605 10.6099 45.0171 10.0255C44.7737 9.44112 44.71 8.79808 44.8341 8.1777C44.9581 7.55732 45.2644 6.98746 45.7142 6.54019C46.1639 6.09292 46.7369 5.78833 47.3607 5.66492C47.9845 5.54152 48.6311 5.60486 49.2187 5.84692C49.8063 6.08898 50.3086 6.49889 50.6619 7.02483C51.0153 7.55076 51.2039 8.16909 51.2039 8.80163C51.2039 9.64983 50.8651 10.4633 50.262 11.0631C49.6589 11.6628 48.841 11.9998 47.9881 11.9998Z" fill="#080E32" />
      </svg>
    </Container>
  )

export default MenuIcon;
