import { useNavigate } from 'react-router-dom';

import LogoSeazone from '../icons/LogoSeazone';
import LogoUniverseFinances from '../icons/Universes/LogoUniverseFinances';
import LogoUniverseHost from '../icons/Universes/LogoUniverseHost';
import LogoUniverseOperational from '../icons/Universes/LogoUniverseOperational';
import LogoUniverseOwner from '../icons/Universes/LogoUniverseOwner';
import LogoUniversePartner from '../icons/Universes/LogoUniversePartner';

import { UniversesOptions, useMenuLateral } from '../../../context/MenuLateral';
import { useScreenResize } from '../../../hooks/useSceenResize/useScreenResize';

import Footer from '../Footer';
import RetractMenuButton from '../icons/RetractMenuButton';

import { ButtonRetractMenu } from '../MenuLateral/styles';

import {
  NavBar,
  NavItem,
  Title,
  ItemContainer,
} from './styles';

interface INavItem {
  title: UniversesOptions,
  permissions: string[],
  logoUniverse: any,
};

const NavItemUniverse = ({
  title,
  permissions,
  logoUniverse,
}: INavItem) => {
  const { shouldBeVisibleTo, setRetractMenu, universeActive, setUniverseActive } = useMenuLateral();

  const handleClick = () => {
    setRetractMenu((state: boolean) => universeActive === title ? !state : state);
    setUniverseActive(title);
  };

  return (
    <NavItem
      onClick={() => handleClick()}
      shouldBeVisible={shouldBeVisibleTo(permissions)}
    >
      <ItemContainer>
        {logoUniverse}
        <p>{title}</p>
      </ItemContainer>
    </NavItem>
  );
}

const Universes = () => {
  const { isMobile } = useScreenResize(900);
  const { setOpenMenu } = useMenuLateral();
  const navigate = useNavigate();

  return (
    <>
      <NavBar>
        {!isMobile && <LogoSeazone onClick={() => navigate('/home')} />}

        {isMobile && (
          <ButtonRetractMenu onClick={() => setOpenMenu(false)}>
            <RetractMenuButton />
          </ButtonRetractMenu>
        )}
        <Title>SAPRON</Title>

        <NavItemUniverse
          title="Operacional"
          permissions={['Admin', 'SeazoneOnboarding', 'SeazoneAdministrative', 'Attendant', 'Host']}
          logoUniverse={<LogoUniverseOperational />}
        />

        <NavItemUniverse
          title="Financeiro"
          permissions={['Admin', 'SeazoneOnboarding', 'SeazoneAdministrative', 'Attendant', 'Host']}
          logoUniverse={<LogoUniverseFinances />}
        />

        <NavItemUniverse
          title="Proprietário"
          permissions={['Admin', 'SeazoneOnboarding', 'SeazoneAdministrative']}
          logoUniverse={<LogoUniverseOwner />}
        />

        <NavItemUniverse
          title="Anfitrião"
          permissions={['Admin', 'SeazoneOnboarding', 'SeazoneAdministrative', 'Attendant', 'Host']}
          logoUniverse={<LogoUniverseHost />}
        />

        <NavItemUniverse
          title="Parceiros"
          permissions={['Admin', 'SeazoneOnboarding', 'Partner']}
          logoUniverse={<LogoUniversePartner />}
        />
      </NavBar>

      <Footer />
    </>
  );
}

export default Universes;
