import request from '../request';
import { IAccountOwnerPerspective } from './types';

export const getAccountOwnerPerspective = async (search?: string) => {
  if (search) {
    const { data } = await request.get<IAccountOwnerPerspective[]>(`/account/owner_perspective?search=${search}`);
    return data;
  }

  const { data } = await request.get<IAccountOwnerPerspective[]>('/account/owner_perspective/');

  return data;
};
