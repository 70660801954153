import styled from 'styled-components';

export const Wrapper = styled.div`s
  #title-modal {
    font-family: 'Inter' !important;
    font-size: 22px !important;
    font-weight: 700 !important;
    line-height: 32px !important;
    text-align: left !important;
    color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
  }

  #content-modal {
    @media(min-width: 900px) {
      padding: 0px 32px !important;
    }
  }

  #header-modal {
    padding: 14px 0px 10px 0px !important;
    margin-bottom: 24px !important;
  }

  #footer-modal {
    div {
      min-height: 0px !important;
      padding: 17px 32px !important; 
    }
  }

  .labelClassName {
    font-family: 'Inter' !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 16.94px !important;
    text-align: left !important;
    color: ${({ theme }) => theme.palette.blue._250.hex()} !important;
  }
`;

export const Container = styled.form`
  width: 100%;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;

  @media(max-width: 900px) {
    flex-direction: column;
  }
`;

