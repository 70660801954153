import { useContextSelector } from 'use-context-selector';
import { RequestSupportModalContext } from '../../context/RequestSuportModalContext';

export function useRequestSupportModal() {
  const isOpenedReportErrorModal = useContextSelector(RequestSupportModalContext,
    (state) => state.isOpenedReportErrorModal);
  const handleCloseReportBugModal = useContextSelector(RequestSupportModalContext,
    (state) => state.handleCloseReportBugModal);
  const handleOpenReportBugModal = useContextSelector(RequestSupportModalContext,
    (state) => state.handleOpenReportBugModal);
  const isOpenedRequestHostOpsModal = useContextSelector(RequestSupportModalContext,
    (state) => state.isOpenedRequestHostOpsModal);
  const setIsOpenedRequestHostOpsModal = useContextSelector(RequestSupportModalContext,
    (state) => state.setIsOpenedRequestHostOpsModal);

  return {
    isOpenedReportErrorModal,
    handleCloseReportBugModal,
    handleOpenReportBugModal,
    isOpenedRequestHostOpsModal,
    setIsOpenedRequestHostOpsModal,
  };
}
