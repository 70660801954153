import { useContextSelector } from "use-context-selector";
import { FavoriteActivitiesContext } from "../context/FavoriteActivitiesContext";

const useActivity = () => {
  const openModalAddActivity = useContextSelector(FavoriteActivitiesContext, (state) => state.openModalAddActivity);
  const setOpenModalAddActivity = useContextSelector(FavoriteActivitiesContext, (state) => state.setOpenModalAddActivity);

  const favoriteActivities = useContextSelector(FavoriteActivitiesContext, (state) => state.favoriteActivities);
  const handleAddActivity = useContextSelector(FavoriteActivitiesContext, (state) => state.handleAddActivity);
  const handleRemoveActivity = useContextSelector(FavoriteActivitiesContext, (state) => state.handleRemoveActivity);

  return {
    openModalAddActivity,
    setOpenModalAddActivity,
    favoriteActivities,
    handleAddActivity,
    handleRemoveActivity,
  }
}

export default useActivity;
