import styled, { css } from 'styled-components';

const animationTime = '0.5s';

const MAX_SIZE_MOBILE = 900;

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 9999;
`;

export const Container = styled.div<{
  retractMenu: boolean;
  shouldBeVisible: boolean;
}>`
  display: flex;
  justify-content: space-between;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10000;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.blue._980.hex()};
  user-select: none;
  border-radius: 0px 8px 8px 0px;
  border-right: 1px solid #E0E0E0;

  ${({ shouldBeVisible }) => !shouldBeVisible && css`
    display: none;
  `}

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    border-radius: 8px 0px 0px 8px;
    border-right: none;

    left: auto;
    top: auto;
    right: 0;
    bottom: 0;
    height: calc(100% - 54px);
  }
`;

export const ContentLeft = styled.div`
  padding: 32px 16px;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: inline-flex;
  flex-shrink: 0;
  max-width: 104px;
  background-color: ${({ theme }) => theme.palette.blue._980.hex()};
  
  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    width: 344px;
    max-width: 344px;
    border-radius: 8px 0px 0px 8px;
    border-left: 1px solid #E0E0E0;
    padding: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: row;
  }
`;

export const ContentRight = styled.div<{
  retractMenu: boolean;
}>`
  display: ${({ retractMenu }) => (!retractMenu ? 'flex' : 'none')};
  flex-direction: column;
  min-width: ${({ retractMenu }) => (!retractMenu ? '258px' : '0px')};
  padding: 32px 0px;
  background-color: ${({ theme }) => theme.palette.blue._980.hex()};
  gap: 6px;
  border-radius: 0px 8px 8px 0px;
  border-right: 1px solid #E0E0E0;

  transition: min-width 0.2 ease-in-out;
  animation-duration: ${animationTime};
  animation-fill-mode: forwards;
  animation-timing-function: ${({ retractMenu }) => (retractMenu ? 'ease-in' : 'ease-out')};

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    border-radius: 8px 0px 0px 8px;
    border-left: 1px solid #E0E0E0;
    border-right: none;
    min-width: 344px;
  }
`;

export const NavBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  gap: 4px;
  padding: 8px 24px 0 0;
  overflow-y: auto;
  
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
  
  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    max-height: 56.5vh;
    padding: 8px 24px 0 32px;
  }
`;

export const TitleContainerNavBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-bottom: 2px solid #fff;
  position: relative;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    width: calc(100% - 48px);
    margin-left: 24px;
    border-bottom: 2px solid #FFF;
  }
`;

export const TitleNavBar = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: stretch;
  width: 100%;
  color: #FFF;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.32px;
  padding-bottom: 10px;
  padding-left: 8px;
`;

export const SubtitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-top: 16px;
  gap: 8px;
`;

export const SubtitleNavBar = styled.p`
  color: #FFF;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0.32px;
`;

export const LogoUniverse = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 4px;
`;

export const ButtonRetractMenu = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 45px;
  left: 358px;
  z-index: 100100 !important;

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    top: 20px;
    left: 270px;
  }
`;

export const FooterContainer = styled.div`
  margin-left: 16px;
`;
