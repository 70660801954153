import styled from 'styled-components';

const MAX_SIZE_MOBILE = 900;

export const Container = styled.div`
  width: 100%;
  max-width: 72px;
  padding-top: 8px;
  margin-bottom: 32px;  
  position: absolute;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;  
  border-top: 2px solid #FFF;
  
  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    width: 100%;
    max-width: 310px;
    align-items: flex-start;
  }
`;

export const ButtonLogout = styled.button`
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  color: #FFF;
  width: 100%;
  padding: 17px 8px;
  margin-bottom: 8px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    color: #FFF;
    font-family: "Plus Jakarta Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.32px;
    padding: 16px 0;
  }

  span {
    color: #C5C5C5;
    font-family: "Plus Jakarta Sans";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.32px;
    padding-top: 6px;
  }

  @media (max-width: ${MAX_SIZE_MOBILE}px) {
    flex-direction: row;
    align-items: center;

    p {
      padding: 0 16px 0 16px;
    }
    
    span {
      padding: 0 0 0 6px;
    }
  }
`;
