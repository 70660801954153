import { Container } from './styles';

interface ILogoUniverseOwner {
  color?: 'white' | 'blue',
};

const LogoUniverseOwner = ({ color = 'white' }: ILogoUniverseOwner) => (
  <Container>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.7137 13.556C16.6323 13.4752 16.5249 13.4302 16.4108 13.4302H15.2831V12.3444C15.2831 12.2311 15.2378 12.1227 15.1565 12.0436L14.3426 11.2353C14.2612 11.1544 14.1538 11.1094 14.0397 11.1094H12.8172V10.2111C12.8172 10.0978 12.7719 9.98947 12.6905 9.91031L11.8976 9.12284C12.2433 8.31621 12.3742 7.43123 12.2777 6.54629C12.0159 4.16302 10.0827 2.26315 7.68145 2.02572C6.12836 1.87239 4.60557 2.40987 3.50796 3.49984C2.41048 4.5898 1.87097 6.10219 2.02621 7.6471C2.26702 10.0238 4.17417 11.9411 6.56464 12.2061C7.45151 12.3061 8.34089 12.1786 9.15057 11.8394L15.2269 17.8742C15.3083 17.955 15.4157 18 15.5298 18H17.5704C17.807 18 18 17.8083 18 17.5733V15.0134C18 14.9001 17.9547 14.7918 17.8733 14.7126L16.7137 13.556ZM6.07874 7.65117C5.18936 7.65117 4.46777 6.93452 4.46777 6.05123C4.46777 5.16793 5.18936 4.45129 6.07874 4.45129C6.96813 4.45129 7.68971 5.16793 7.68971 6.05123C7.68971 6.93452 6.96813 7.65117 6.07874 7.65117Z" fill={color === 'blue' ? '#5274D8' : color} />
    </svg>
  </Container>
);

LogoUniverseOwner.defaultProps = {
  color: 'white',
};

export default LogoUniverseOwner;
