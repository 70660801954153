import { Container } from './styles';

interface IIconAdd {
  handleAdd: Function,
}

const IconAdd = ({ handleAdd }: IIconAdd) => (
  <Container onClick={() => handleAdd()}>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16.001" r="12" fill="#7E869E" fillOpacity="0.25" />
      <path d="M16 20.001L16 12.001" stroke="#222222" strokeWidth="1.2" strokeLinecap="square" />
      <path d="M20 16.001L12 16.001" stroke="#222222" strokeWidth="1.2" strokeLinecap="square" />
    </svg>
  </Container>
);

export default IconAdd;
