import styled from 'styled-components';

type Props = {
  isCustomStyle: boolean;
};

export const Container = styled.div<Props>`
  width: 100%;
  height: 100vh;
  padding-left: 100px;

  @media (max-width: 900px) {
    padding-left: 0
  }

  @media (max-width: 900px) {
    margin-top: 55px;
  }

  @media (max-width: 800px) {
    margin-top: 0;
  }
`;
